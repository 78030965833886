.home-container {
  // margin-bottom: 6rem;
  &__navPanel {
    display: none;
    box-shadow: 0 4px 6px -2px rgba(46, 46, 46, 0.25);
    margin-bottom: 28px;
    &__detail {
      display: flex;
      justify-content: space-between;
      padding: 24px 20px;
      margin: 0 auto;
      max-width: 1440px;
      align-items: center;
      &__title {
        p {
          font-size: 30px;
          font-weight: 600;
          line-height: 43px;
          color: $color-grey-1;
        }
      }
      &__content {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
        &-ticker {
          display: inline-flex !important;
          justify-content: space-between;
          align-items: center;
          gap: 24px;
          .analogClock-container {
            gap: 24px;
          }
          .analogClock-container::after {
            align-self: stretch;
            content: "";
            border: 1px solid $color-grey-4;
          }
          .react-clock {
            width: 80px !important;
            height: 80px !important;
          }
          .ticker-container-wraper {
            max-width: 299px;
          }
        }
        &-btn {
          display: inline-flex;
          align-items: center;
          gap: 12px;
          button {
            border: unset;
            font-size: 16px;
            font-weight: 500;
          }
          &-login {
            padding: 12px;
            background-color: $color-grey-5;
            color: $color-grey-2;
          }
          &-signup {
            padding: 12px;
            background-color: $color-primary-nordea;
            color: $color-white;
          }
        }
      }
    }
  }
  &__stockListing {
    display: flex;
    justify-content: space-between;
    padding: 32px 0 0;
    margin: 0 auto;
    max-width: 1440px;
    position: relative;
    @media screen and (max-width: 1600px) {
      padding: 32px 0 0;
    }
    @media screen and (max-width: 1440px) {
      padding: 32px 30px 0;
    }
    @media screen and (max-width: 900px) {
      padding: 32px 0px 0;
    }
  }
  @media screen and (max-width: 1440px) {
    &__stockListing {
      padding: 24px 30px 0px 30px;
    }
  }

  &__searchBar {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    position: relative;
    flex-direction: column;
    align-items: center;
    .quick-links {
      display: flex;
      justify-content: space-between;
      margin: 56px 0;
      width: 42rem;
      .item {
        text-align: center;
        text-decoration: none;

        .wrap-image {
          width: 52px;
          height: 52px;
          margin: 0 auto 12px auto;
          background: #dcedff;
          padding: 4px;
        }

        p {
          color: $color-grey-2;
          font-family: "Inter", sans-serif;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
          max-width: 135px;
        }

        &.item-new {
          .wrap-image {
            position: relative;
            &::before {
              position: absolute;
              content: "";
              top: -5px;
              right: -5px;
              display: block;
              width: 12px;
              height: 12px;
              background-color: #ff4747;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
  &__data-source-area {
    display: flex;
    margin: 0 0 20px 30rem;
    &__powered-by {
      font-size: 12px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0;
      position: relative;
      margin: 0 0 0 12px;
      color: $color-grey-3;
      .blue-txt {
        color: #1800a8;
        // font-style: italic;
      }
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 10px;
      //   left: -10px;
      //   border-left: 1px solid #b3afaf;
      //   height: 12px;
      // }
    }
  }
  &__default-card {
    width: 100%;
  }
  .tab-container {
    color: $color-grey-1;
    font-size: 24px;
    font-weight: 500;
    border-radius: 12px;
    line-height: 36px;
    max-width: 1440px;
    margin: 0 auto;
    padding: 5px 0 10px;
    .answer-bar-container,
    .related-search-container {
      margin-top: 1rem;
    }
  }
  .home-tab {
    background: $color-grey-6;
    &__list {
      margin: 0 auto;
      max-width: 1440px;

      @media screen and (max-width: 1440px) {
        margin: 0 30px;
      }
      @media screen and (max-width: 900px) {
        margin: 0 60px;
      }
      @media screen and (max-width: 680px) {
        margin: 0 25px;
      }
      .cds--tabs__nav-link {
        font-size: 15px;
        line-height: 18.15px;
        padding-inline: unset;
        border-block-end: unset;
        color: $color-grey-2;
      }
      .cds--tab--list {
        gap: 60px;
        height: 54px;
      }
      .cds--tabs__nav-item--selected {
        border-block-end: 4px solid $color-grey-2 !important;
        font-weight: 700;
        padding-top: 4px;
      }
      &:not(.cds--tabs--contained)
        .cds--tabs__nav-item:not(.cds--tabs__nav-item--selected):not(
          .cds--tabs__nav-item--disabled
        ):hover {
        border-block-end: unset !important;
      }
      .cds--tabs__nav-link:focus {
        outline: unset !important;
      }
    }
  }
  .cds--tab-content:focus {
    outline: unset;
    outline-offset: 0;
  }
  .cds--tab-content {
    padding: 0;
  }

  // loader
  /* Add this CSS to your stylesheets or inline style */

  // .loader-container {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: 100px; /* Adjust the height as needed */
  // }

  // .loader {
  //   border: 8px solid #f3f3f3; /* Light grey */
  //   border-top: 8px solid #3498db; /* Blue */
  //   border-radius: 50%;
  //   width: 50px;
  //   height: 50px;
  //   animation: spin 1s linear infinite;
  // }

  // @keyframes spin {
  //   0% {
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     transform: rotate(360deg);
  //   }
  // }

  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 200px;
      height: 200px;
    }
    &-content {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 6px;
      color: $color-grey-2;
      &-head {
        font-size: 17px;
        font-weight: 600;
        line-height: 20.57px;
      }
      &-sub {
        font-size: 13px;
        font-weight: 400;
        line-height: 15.73px;
      }
    }
  }

  &__body {
    position: relative;
    margin-top: 2em;
    &__header {
      font-family: Inter;
      font-size: 20px;
      font-weight: 400;
      // padding: 0 0 10px 60px;
      max-width: 1440px;
      margin: 0 auto;
      color: $color-grey-1;
      padding: 24px 0px;
    }
  }

  .resultFlow {
    margin-top: unset;
    .home-container__body__home-slider {
      position: unset;
      // display: flex;
      justify-content: space-between;
      // padding: 6px 20px;
      margin: 0 auto;
      max-width: 952px;
      align-items: center;

      @media screen and (max-width: 768px) {
        padding: 6px 20px;
      }
      .slick-arrow {
        position: absolute;
        top: 28px;
        background-image: url("../../assets/images/slider_arrow.svg");
        background-size: cover; /* or contain, or a specific size */
        background-repeat: no-repeat; /* or repeat-x, repeat-y, or repeat */
        background-position: center center; /* or top, bottom, left, right, etc. */
        height: 12px;
        width: 14px;
        &.slick-prev {
          left: 20px;
          transform: scaleX(-1);
          &::before {
            display: none;
          }
        }
        &.slick-next {
          transform: scaleY(1);
          left: 40px;
          &::before {
            display: none;
          }
        }
        &.slick-prev:hover,
        &.slick-prev:focus,
        &.slick-next:hover,
        &.slick-next:focus {
          opacity: 1;
        }

        &.slick-prev.slick-disabled,
        &.slick-next.slick-disabled {
          opacity: 0.25;
        }
      }
      .home-container__body____home-slider__result1 {
        .tab-container {
          .nav-back {
            color: $color-grey-1;
            font-size: 20px;
            font-weight: 400;
            margin: 0 auto;
            max-width: 1440px;
            padding: 10px 0;
          }
          button {
            background-color: unset;
            border: unset;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    &__navPanel__detail {
      padding: 15px 60px;
      &__title p {
        font-size: 28px;
      }
      &__content-btn {
        display: none;
      }
    }
    &__navPanel {
      margin-bottom: 0;
      display: block;
    }
    &__stockListing {
      display: none;
    }
    &__data-source-area {
      margin: 0 0 20px 26.4rem;
      &__powered-by {
        font-size: 11px;
      }
    }
  }

  @media screen and (max-width: 680px) {
    &__searchBar {
      padding-top: 25px;
    }
    &__navPanel {
      // display: none;
      &__detail {
        padding: 15px 25px;
        &__title {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    &__navPanel {
      // display: none;
      &__detail {
        padding: 15px 25px;
        &__title {
          display: none;
        }
        &__content {
          &-ticker {
            .analogClock-container {
              display: none;
            }
          }
        }
      }
    }
  }
}
