.breadcrumb{
    margin: 0;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    &-item{
        text-transform: capitalize;
        padding-left: 8px;
        a{
            color: $color-white;
            text-decoration: none;
        }
        &::before{
            content: none !important;
        }
        &-divider{
            display: inline-flex;
            vertical-align: middle;
            padding-left: 8px;
            .icon-chevron-thin-right{
                font-size: 12px;
                font-weight: 700;
                color: $color-white;
            }
        }
        
    }
    .active{
        a{
            color: $color-black;
            font-weight: 700;
            padding-left: 14px;
        }
    }
}