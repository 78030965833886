$fontInter: "Inter";
$fontSans: "Public Sans";

$baseFont: var(--default-font);
$defaultBackground: var(--default-background);

$color-white: var(--color-white);
$color-black: var(--color-black);
$color-blue: var(--color-blue);
$color-grey-1: var(--color-grey-1);
$color-grey-2: var(--color-grey-2);
$color-grey-3: var(--color-grey-3);
$color-grey-4: var(--color-grey-4);
$color-grey-5: var(--color-grey-5);
$color-grey-6: var(--color-grey-6);
$color-dark-grey: var(--color-dark-grey);
$color-green-1: var(--color-green-1);
$color-green-2: var(--color-green-2);
$color-orange: var(--color-orange);
$color-red: var(--color-red);

/** NORDEA */
$color-primary-nordea: var(--color-primary-nordea);
