.ticker-container{
    display: flex;
    align-items: center;
    gap: 12px;

    &-wraper {
        max-width: 235px;
        .ticker-info {
            // background-color: white;
            // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            // border-radius: 5px;
            display: inline-flex;
            flex-direction: column;
            // padding: 20px;
            margin: 2px;
            gap: 8px;
    
            .company-name {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: normal;
                // margin: 8px 0 18px;
                gap: 12px;
                color: $color-grey-2;
            }
            .market-share{
                display: flex;
                flex-direction: row;
                .market-price {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: normal;
                    display: flex;
                    align-items: baseline;
                    column-gap: 4px;
                    color: $color-grey-1;
            
                    .currency-code {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
                .market-count {
                    font-size: 13px;
                    font-weight: 700;
                    .change-value {
                      line-height: 24px;
                    }
                    .change-percentage {
                      line-height: 24px;
                    }             
                }
                .market-down {
                    color: #ff0000;
                }                
                .market-up {
                    color: $color-green-1;
                }
                .market-nochange {
                    color: #8e8b8b;
                }
            }
            .d-flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .market-status {
                color: $color-grey-3;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                gap: 4px;
                flex-direction: column;
                div{
                    display: flex;
                    gap: 4px;
                }
            }
        }
        
    }
      
}