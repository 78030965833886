.audio-card {
  border: 1px solid #e0e0e0;
  padding: 12px;
  border-radius: 12px;
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      &__img {
        width: 52px;
      }
      &__content {
        height: 52px;
        font-family: "Inter";
        font-weight: 400;
        color: $color-grey-3;
        &__name {
          font-size: 14px;
          line-height: 19px;
        }
        &__date {
          font-size: 12px;
          line-height: 17px;
          margin-top: 13px;
          &__upcoming {
            padding: 2px 4px;
            border-radius: 4px;
            background: #f2994a;
            color: $color-white;
            margin-left: 10px;
          }
        }
      }
    }
    &__audio {
      &__img {
        width: 42px;
        cursor: pointer;
      }
    }
    &__subscribe {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #0000a0;
    }
  }
  &__footer {
    height: 56px;
    margin: 10px 0 12px;
    &__player {
      &__progress {
        height: 30px;
        &__input {
          width: 100%;
          height: 100%;
        }
        input[type="range"] {
          height: 17px;
          -webkit-appearance: none;
          margin: 10px 0;
          width: 100%;
        }
        input[type="range"]:focus {
          outline: none;
        }
        input[type="range"]::-webkit-slider-runnable-track {
          width: 100%;
          height: 3px;
          cursor: pointer;
          animate: 0.2s;
          box-shadow: 0px 0px 0px #dee7f0;
          background: #dee7f0;
          border-radius: 5px;
          border: 0px solid #dee7f0;
        }
        input[type="range"]::-webkit-slider-thumb {
          box-shadow: 0px 0px 0px #000031;
          border: 0px solid #00001e;
          height: 11px;
          width: 11px;
          border-radius: 6px;
          background: #3c444b;
          cursor: pointer;
          -webkit-appearance: none;
          margin-top: -4px;
        }
        input[type="range"]:focus::-webkit-slider-runnable-track {
          background: #dee7f0;
        }
        input[type="range"]::-moz-range-track {
          width: 100%;
          height: 3px;
          cursor: pointer;
          animate: 0.2s;
          box-shadow: 0px 0px 0px #dee7f0;
          background: #dee7f0;
          border-radius: 5px;
          border: 0px solid #dee7f0;
        }
        input[type="range"]::-moz-range-thumb {
          box-shadow: 0px 0px 0px #000031;
          border: 0px solid #00001e;
          height: 11px;
          width: 11px;
          border-radius: 6px;
          background: #3c444b;
          cursor: pointer;
        }
        input[type="range"]::-ms-track {
          width: 100%;
          height: 3px;
          cursor: pointer;
          animate: 0.2s;
          background: transparent;
          border-color: transparent;
          color: transparent;
        }
        input[type="range"]::-ms-fill-lower {
          background: #dee7f0;
          border: 0px solid #dee7f0;
          border-radius: 10px;
          box-shadow: 0px 0px 0px #dee7f0;
        }
        input[type="range"]::-ms-fill-upper {
          background: #dee7f0;
          border: 0px solid #dee7f0;
          border-radius: 10px;
          box-shadow: 0px 0px 0px #dee7f0;
        }
        input[type="range"]::-ms-thumb {
          margin-top: 1px;
          box-shadow: 0px 0px 0px #000031;
          border: 0px solid #00001e;
          height: 11px;
          width: 11px;
          border-radius: 6px;
          background: #3c444b;
          cursor: pointer;
        }
        input[type="range"]:focus::-ms-fill-lower {
          background: #dee7f0;
        }
        input[type="range"]:focus::-ms-fill-upper {
          background: #dee7f0;
        }
      }
      &__time {
        display: flex;
        justify-content: space-between;
        &__current-time,
        &__duration {
          font-family: Inter;
          font-size: 13px;
          font-weight: 400;
          line-height: 15px;
          color: $color-grey-2;
        }
      }
    }
    &__poweredby {
      font-family: Inter;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      float: right;
      color: $color-grey-3;
      margin-top: 10px;
    }
  }
}
