.searchBar-container {
  display: flex;
  height: 52px;
  width: 708px;
  position: relative;
  padding: 14px 5px 14px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: $defaultBackground;
  box-shadow: -2px -2px 8px 2px rgba(167, 201, 255, 0.5),
    2px 2px 8px 2px rgba(167, 201, 255, 0.5);

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 6px;
    padding: 1px;
    background: linear-gradient(88.78deg, #0000a0 0.72%, #0094d3 98.95%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  @media screen and (max-width: 768px) {
    width: fit-content;
    margin: 0 auto;
  }
  input {
    width: 100%;
    border: none;
    color: $color-grey-2;
    text-overflow: ellipsis;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.57px;
    background: transparent;
    // border-right: 2px solid #828282;
    margin-right: 12px;
    z-index: 1;
    &:focus {
      outline: none; /* Removes the default focus border */
    }
  }
  .search-icon {
    display: flex;
    cursor: pointer;
    background: url("../../../assets/images/search-btn.svg") no-repeat;
    width: 151px;
    height: 38px;
    z-index: 2;
    img {
      display: none;
    }
  }
  .error-message {
    position: absolute;
    color: #d50c0c;
    top: 115%;
  }

  /* Add this CSS to your stylesheets or inline style */

  &.auto-suggest-active {
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }
  &.active {
    // border-radius: 24px 24px 0 0;
  }
  .suggestion-list {
    position: absolute;
    background-color: $color-white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    min-height: 36px;
    width: 708px;
    right: -1px;
    top: 102%;
    // border-radius: 0 0 14px 14px;
    border-top: none;
  }

  .suggestion-list li:hover,
  .suggestion-list .active {
    background-color: #f0f0f0;
  }
  .suggestion-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .suggestion-list li {
    padding: 10px 26px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  @media screen and (max-width: 900px) {
    width: 634px;
    height: 48px;
    padding: 14px 7px 14px 7px;
    input {
      font-size: 16px;
    }
    .search-icon img {
      // width: 16px;
      height: 30px;
    }
    .suggestion-list {
      width: 634px;
    }
  }
  @media screen and (max-width: 680px) {
    width: 90%;
  }
}

// .data-source-area {
//   display: flex;
//   margin-left: 30rem;
//   &__powered-by {
//     font-size: 11px;
//     font-weight: 400;
//     line-height: 13.31px;
//     text-align: left;
//     letter-spacing: 0;
//     position: relative;
//     margin: 10px 0 0 45px;
//     color: $color-grey-3;
//     .blue-txt {
//       color: #1800a8;
//       // font-style: italic;
//     }
//     // &::before {
//     //   content: "";
//     //   position: absolute;
//     //   top: 10px;
//     //   left: -10px;
//     //   border-left: 1px solid #b3afaf;
//     //   height: 12px;
//     // }
//   }
// }
// .trending-container {
//   width: 650px;
//   height: 108px;
//   text-align: center;
//   margin: 30px 0;
//   &__header {
//     font-size: 13px;
//     font-weight: 500;
//     line-height: 15.73px;
//     color: $color-grey-3;
//   }
//   &__body {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     gap: 10px;
//     margin-top: 20px;
//     &__card {
//       min-width: 199px;
//       height: 34px;
//       padding: 8px 14px 8px 14px;
//       border-radius: 4px;
//       border: 1px solid $color-grey-5;
//       background: $defaultBackground;
//       font-family: Inter;
//       font-size: 15px;
//       font-weight: 400;
//       line-height: 18.15px;
//       color: $color-grey-3;
//     }
//   }
// }
