.searchBar-container {
  padding: 14px 5px 14px 10px;
}

.common-card__list {
  direction: rtl;
}
.share-chart-card__tab__market__icon {
  position: absolute;
  top: 2px;
  left: 0;
  right: auto;
  direction: ltr;
}
.share-chart-card__tab__details__table tr td:nth-child(2) {
  border-left: 1px solid var(--color-grey-5);
  border-right: 0px;
}
.share-chart-card__tab__details__table tr td:nth-child(odd) {
  text-align: right;
}
.share-chart-card__tab__details__table tr td:nth-child(even) {
  text-align: left;
}

.common-card-large xl {
  direction: rtl;
}
.common-card-large {
  direction: rtl;
}

.shareholding-chart-large__tab__scroll__list {
  position: absolute;
  top: 13px;
  width: -moz-fit-content;
  width: fit-content;
  left: 23px;
  right: auto;
}
// .profit-table .doubleBarWithTableXl-chart__data{
//   direction: ltr;
// }

// .doubleBarWithTableXl-chart__data__table
//   tbody
//   tr:first-child
//   td:first-child::before,
// .doubleBarWithTableXl-chart__data__table
//   tbody
//   tr:nth-child(2)
//   td:first-child::before {
//   content: "";
//   position: absolute;
//   right: 0;
//   top: 0;
//   height: 32px;
//   width: 5px;
// }

.last-dividend-paid-container {
  direction: rtl;
}
.text-response__pointer {
  margin-right: 21px;
}

.send-enquiry-form-box__captcha_box {
  width: 333px;
  padding: 10px 0;
}
.earning-audio-player .rhap_current-time {
  left: auto;
  right: 0;
}
.earning-audio-player .rhap_total-time {
  right: auto;
  left: 0;
}
.splide__arrows {
  left: -16px;
  right: auto;
  top: -8%;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: -80px;
}

.splide__slide {
  margin-left: 20px;
  margin-right: 0px !important;
}

.market-share-data {
  direction: ltr;
}
.market-status {
  direction: ltr;
  align-items: flex-end;
}
.home-container__data-source-area {
  display: flex;
  margin: 5px 0 20px -31rem;
}
.financial-reminder.range .financial-reminder__report {
  border-right: 3px solid var(--color-grey-3);
  border-left: none;
  padding-right: 12px;
}

.announcement-page__header__filter__years
  .cds--dropdown
  .cds--list-box__menu-icon,
.announcement-page__header__filter__topics
  .cds--dropdown
  .cds--list-box__menu-icon {
  right: auto !important;
  left: 5px !important;
}

.announcement-page__header__filter__years
  .cds--dropdown
  .cds--list-box__menu-item__option,
.announcement-page__header__filter__topics
  .cds--dropdown
  .cds--list-box__menu-item__option {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  color: var(--color-grey-1);
  margin-left: 0;
  margin-right: 1rem;
  height: auto;
}
.cds--list-box__menu-item--active {
  .cds--list-box__menu-item__option {
    color: #2f80ed !important;
  }
}

.financial-reports-page__header__filter__years
  .cds--dropdown
  .cds--list-box__menu-item__option,
.financial-reports-page__header__filter__topics
  .cds--dropdown
  .cds--list-box__menu-item__option,
.financial-reports-page__header__filter__period
  .cds--dropdown
  .cds--list-box__menu-item__option {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  color: var(--color-grey-1);
  margin-right: 1rem;
  margin-left: 0;
  height: 35px;
}

.financial-reports-page__header__filter__years
  .cds--dropdown
  .cds--list-box__menu-icon,
.financial-reports-page__header__filter__topics
  .cds--dropdown
  .cds--list-box__menu-icon,
.financial-reports-page__header__filter__period
  .cds--dropdown
  .cds--list-box__menu-icon {
  right: auto !important;
  left: 0px;
}

.peer-comparison-chart-large__tab__scroll {
  position: absolute;
  top: 3px;
  right: 230px;
}
.cds--tabs .cds--tab--list {
  direction: rtl;
}
.last-dividend-paid-card {
  &__value {
    padding: 10px 10px 4px;
    background: #e8f2ff;
    border-left: 0;
    border-right: 4px solid var(--color-blue);
  }
  &__list {
    direction: ltr;
  }
}

.earning-audio-player__event_name_card {
  position: absolute;
  color: #fff;
  bottom: 14%;
  font-weight: 500;
  right: 6%;
}
.earning-audio-player__play_list_event_name_card {
  position: absolute;
  color: #fff;
  bottom: 10%;
  font-weight: 400;
  font-size: 10px;
  right: 5%;
}
.splide__slide .rhap_play-pause-button img {
  transform: rotate(180deg);
}
.earning-audio-player__play_card_name {
  direction: ltr;
}

.rhap_progress-container {
  direction: rtl;
  transform: scaleX(-1);
  margin: 0 calc(-13px + 1%);
}
.rhap_progress-bar {
  direction: ltr;
}
.rhap_progress-indicator {
  transform: scaleX(-1);
}

.analyst-range-slider__flex_row_progress_bar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.share-chart-card__tab__inline_row {
  border-left: 1px solid var(--color-grey-5);
  padding: 0px 0px 0px 10px;
  border-right: 0;
}

.ticker-container-wraper .ticker-info .market-share .market-price {
  justify-content: flex-end;
}

.ticker-container-wraper .ticker-info .d-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.ticker-container-wraper .ticker-info .market-status div {
  display: flex;
  gap: 4px;
  direction: rtl;
}
