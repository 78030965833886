@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rf9tmx');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rf9tmx#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rf9tmx') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rf9tmx') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rf9tmx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-euroland-ir .path1 {
  &:before {
    content: $icon-euroland-ir-path1;  
    color: rgb(8, 43, 69);
  }
}
.icon-euroland-ir .path2 {
  &:before {
    content: $icon-euroland-ir-path2;  
    margin-left: -8.4443359375em;  
    color: rgb(8, 43, 69);
  }
}
.icon-euroland-ir .path3 {
  &:before {
    content: $icon-euroland-ir-path3;  
    margin-left: -8.4443359375em;  
    color: rgb(0, 173, 240);
  }
}
.icon-euroland-ir .path4 {
  &:before {
    content: $icon-euroland-ir-path4;  
    margin-left: -8.4443359375em;  
    color: rgb(0, 173, 240);
  }
}
.icon-social-apple {
  &:before {
    content: $icon-social-apple;     
    color: #1877f2;
  }
}
.icon-social-fb {
  &:before {
    content: $icon-social-fb;     
    color: #1877f2;
  }
}
.icon-social-google {
  &:before {
    content: $icon-social-google;     
    color: #1877f2;
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-chevron-thin-down {
  &:before {
    content: $icon-chevron-thin-down; 
  }
}
.icon-chevron-thin-right {
  &:before {
    content: $icon-chevron-thin-right; 
  }
}

