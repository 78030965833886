.trending-search-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 708px;
    gap: 12px;
    margin-bottom: 50px;
    @media screen and (max-width: 900px) {
        width: 634px;
    }
    
    &__header{
        font-size: 13px;
        font-weight: 400;
        color: $color-grey-3;
    }
    &__body{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;
        &__box{
            border-radius: 4px;
            background-color: var(--default-background);
            padding: 8px 14px;
            display: flex;
            cursor: pointer;
            border: 1px solid $color-grey-5;
            flex-wrap: wrap;
        }
        &__content {
            color: $color-grey-3;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0;
            margin: 0;
          }
    }

    @media screen and (max-width: 680px) {
        width: 100%;
        &__header{
            font-size: 12px;
        }
        &__body{
            &__content {
                font-size: 12px;
            }
        }
    }
}