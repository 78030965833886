.financial-reminder {
  position: relative;
  min-height: 316px;
  &__header,
  &__day {
    font-size: 18px;
    font-weight: 500;
    color: $color-grey-1;
    margin: 5px 0 16px;
  }
  &__title {
    font-size: 38px;
    font-weight: 500;
    color: $color-black;
    margin-bottom: 20px;
  }
  &__day {
    color: $color-grey-3;
  }

  &__report {
    border-left: 3px solid #5d5fef;
    padding-left: 12px;
    &__type {
      color: #5d5fef;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &__description {
      font-size: 21px;
      margin: 10px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-height: 1.4;
    }

    &__duration {
      color: $color-grey-3;
      font-size: 18px;
      font-weight: 500;
    }
  }

  &__icons {
    position: absolute;
    display: flex;
    gap: 18px;
    bottom: 0;
    img {
      width: 28px;
    }
  }

  &.single .financial-reminder {
    &__header {
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    &__title {
      font-size: 100px;
      margin-bottom: 10px;
    }
    &__report {
      border-left: 3px solid #9b51e0;
      &__type {
        color: #9b51e0;
      }
    }
    // &__icons {
    //   margin-top: 15px;
    // }
  }
  &.range .financial-reminder {
    &__title {
      font-size: 32px;
    }
    &__report {
      border-left: 3px solid $color-grey-3;
      &__type {
        color: $color-grey-3;
      }
    }
  }
}

.financial-events-cards {
  height: 315px;
  overflow-y: auto;
  &__map_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
      margin-top: 1%;
      // margin-bottom: 2%;
    }
  }
  &__map_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin-top: 6px;
    padding: 0px 6px 0px 0px;
    h5 {
      margin-bottom: 1%;
    }
  }
  &__vertical_line_blue {
    height: 48px;
    border-left: 2px solid #5d5fef;
  }
  &__vertical_line_voilet {
    height: 48px;
    border-left: 2px solid #9b51e0;
  }
  &__date_column {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h5 {
      font-size: 23px;
      font-weight: 600;
    }
  }
  &__event_column {
    width: 68%;
    flex-wrap: wrap;
    text-wrap: wrap;
  }

  &__event_type_column {
    width: 17%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 5px;
  }

  &__event_icon_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5%;
    gap: 10px;
    a {
      cursor: pointer;
    }
    a:hover {
      color: #9b51e0;
    }
  }
  &__hr_custom {
    width: 100%;
    padding: 0;
    margin: 4px;
    background-color: $color-grey-6;
    border: none;
    height: 0.1px;
  }
  &__day_text {
    color: $color-grey-3;
    font-size: 10px;
  }
  &__event_name {
    font-weight: 500;
    font-size: 13px;
    color: $color-grey-1;
    width: 100%;
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide the overflow text */
    text-overflow: ellipsis;
    margin-top: 5px;
  }
  &__event_duration {
    font-weight: 500;
    font-size: 12px;
    color: $color-grey-3;
    margin-top: 5px;
  }
  &__item_type {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    text-align: end;
  }
}

   /* Custom scrollbar styling with !important */
   .financial-events-cards::-webkit-scrollbar {
    width: 5px !important;
  }

  .financial-events-cards::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  .financial-events-cards::-webkit-scrollbar-thumb {
    background-color: #d8d8d8 !important;
    border-radius: 4px !important;
  }

  .financial-events-cards::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important;
  }
