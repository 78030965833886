.meeting-request-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__meeting_text {
    font-size: 13px;
    width: 87%;
    text-align: center;
    font-weight: 500;
    margin-top: 5%;
    text-align: center;
    font-weight: 500;
    padding-bottom: 5%;
  }
  &__meeting_img {
    height: 130px;
    width: 100%;
  }
  &__send_btn {
    width: 75%;
    background-color: #fff;
    color: #000;
    border: 1px solid $color-grey-1;
    cursor: pointer;
    padding: 3%;
    margin-top: 3%;
    font-weight: 500;
  }
  &__request_btn {
    width: 75%;
    background-color: $color-grey-1;
    color: #ffffff;
    cursor: pointer;
    padding: 3%;
    margin-top: 2%;
    font-weight: 500;
  }
}

.send-enquiry-form-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    height: 32px;
  }
  h5 {
    width: 87%;
  }
  &__request_btn {
    width: 87%;
    background-color: $color-grey-1;
    color: #ffffff;
    cursor: pointer;
    padding: 3%;
    margin-top: 16%;
    font-weight: 500;
  }
  &__captcha_box {
    width: 278px;
    padding: 10px 0;
    height: 42px;
    transform: scale(0.8); /* Adjust the scale value to fit your size */
    transform-origin: 0 0;
    margin-top: 2px;

    & > div {
      transform: scale(1.15);
      transform-origin: left top;
    }
  }
  &__enquiry_input {
    margin-top: 3%;
    border: 1px solid $color-grey-5;
    width: 87%;
  }
  &__enquiry_input input {
    background-color: #f9f9f9;
    border-block-end: none;
  }
  &__enquiry_input .cds--text-area {
    background-color: #f9f9f9;
    border-block-end: none;
  }
}

.message-enquiry-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
