.announcement-page {
  margin: 0 auto;
  max-width: 1440px;
  padding-top: 24px;
  &__bar {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: $color-grey-2;
    a {
      text-decoration: none;
      color: $color-grey-2;
    }
    &__span a {
      color: #0f62fe;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
    &__title {
      font-size: 32px;
      font-weight: 600;
      line-height: 38.73px;
      color: $color-grey-1;
    }
    &__filter {
      display: flex;
      gap: 15px;
      &__years,
      &__topics {
        .cds--dropdown {
          min-height: 48px;
          width: 224px;
          // border-bottom: 1px solid #8d8d8d;
          box-shadow: 0px -1px 0px 0px #8d8d8d inset;
          .cds--label {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.3px;
            color: $color-grey-2;
          }

          .cds--list-box__label {
            // background-color: #f4f4f4;
            color: $color-grey-1 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 18px !important;
            letter-spacing: 0.16px !important;
          }
          .cds--list-box__field {
            padding: 15px 16px;
            padding-inline-start: 1rem !important;
            padding-inline-end: 2.4rem !important;
          }
          .cds--list-box__menu-item,
        .cds--list-box__menu-item:active,
        // .cds--list-box__menu-item:focus,
        .cds--list-box__menu-item__option {
            background-color: #f4f4f4;
          }
          .cds--list-box__menu-icon {
            right: 10px !important;
          }
          .cds--list-box__menu-icon {
            right: 6px;
            svg {
              fill: $color-grey-2;
            }
          }

          .cds--list-box__menu-item,
      .cds--list-box__menu-item:active,
      // .cds--list-box__menu-item:focus,
      .cds--list-box__menu-item__option {
            border-block-start-style: unset;
            border-block-end-style: unset;
            border: unset;
            background: $color-white;
            padding-right: 0;
            outline: unset;
          }
          .cds--list-box__menu-item__option {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0;
            color: $color-grey-1;
            margin-right: 0;
            margin-left: 1rem;
            height: 35px;
          }
          .cds--list-box__menu-item__selected-icon {
            display: none;
          }
        }
      }
    }
  }
  &__body {
    &-header {
      width: 100%;
      thead {
        background-color: $color-grey-6;
        tr {
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          color: $color-grey-1;
          border-bottom: 1px solid $color-grey-5;
          th {
            padding: 12px 0;
          }
          th:first-child {
            padding-left: 12px;
          }
          th:last-child {
            padding-right: 12px;
          }
          th:nth-child(1),
          th:nth-child(2) {
            width: 110px;
          }
          th:nth-child(3) {
            width: 700px;
            line-height: 25px;
          }
          th:nth-child(4) {
            width: 250px;
          }
        }
      }
    }

    &__card {
      &__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 29.05px;
        border-bottom: 2px solid $color-grey-5;
        padding: 20px 0;
      }
      &__table {
        width: 100%;
        tr {
          border-bottom: 1px solid $color-grey-5;
          td {
            padding: 12px 6px;
            a {
              text-decoration: none;
              color: #161616;
            }
          }
          td:nth-child(1),
          td:nth-child(2) {
            width: 110px;
          }
          td:nth-child(3) {
            width: 700px;
            line-height: 25px;
          }
          td:nth-child(4) {
            width: 250px;
          }
        }
        :hover {
          cursor: pointer;
          background-color: $color-grey-6;
        }
        &__time {
          font-size: 17px;
          font-weight: 200;
          color: $color-grey-1;
        }
        // &__details {
        //   width: 850px;
        // }
        &__link {
          font-size: 17px;
          font-weight: 500;
          line-height: 20px;
          color: $color-grey-1;
          text-transform: uppercase;
          text-decoration: none;
        }
        &__comments,
        &__thumb-up {
          // width: 18px;
          // height: 16px;
          display: flex;
          height: 100%;
          margin: 0;
          img {
            padding-right: 5px;
          }
        }
      }
    }
    .no-records {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40vh;
      &-msg {
        font-size: 24px;
        font-weight: 500;
        color: $color-grey-1;
      }
    }
  }
}

@media screen and (max-width: 1440px){
  .announcement-page {
    padding: 24px 30px 0px 30px;
  }
}
