.bar-line-chart {
  &__title {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0;
    color: $color-grey-3;
    margin: 0 0px 3px 0px;
  }
  .highcharts-legend-item {
    text {
      font-size: 12px;
      color: $color-grey-2 !important;
      fill: $color-grey-2 !important;
    }
    // .highcharts-point {
    //   d: path("M 2 2 L 14 2 L 14 14 L 2 14 Z");
    //   rx: 0;
    // }
  }
}
