.video-card {
  &__list {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 16px 0;
    gap: 10px;
    &__content {
      height: 52px;
      font-family: "Inter";
      font-weight: 400;
      color: $color-grey-3;
      &__name {
        font-size: 14px;
        line-height: 19px;
      }
      &__date {
        font-size: 12px;
        line-height: 17px;
        margin-top: 13px;
        &__new {
          padding: 2px 4px;
          border-radius: 4px;
          background: #ff4747;
          color: $color-white;
          margin-left: 10px;
        }
      }
    }
  }
  &__footer {
    float: right;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 15px;
    color: $color-grey-3;
  }
}
