.toast-container {
  top: 70px !important;
  right: 20px !important;
  position: fixed;
  z-index: 9999;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  .bg-success {
    background-color: #27ae60 !important;
    color: $color-white;
  }
  // .cds--toast-notification__details{
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   // .cds--toast-notification__title{
  //   //   margin: unset !important;
  //   // }
  // }
}
