.analogClock-container {
    display: flex;
    align-items: flex-start;
    gap: 35px;
    &-timeZone {
      text-align: center;
      gap: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
   
      &__clock-name {
        font-size: 13px;
        font-weight: 500;
      }
   
      .react-clock {
        display: block;
        position: relative;
        // width: 100px !important;
        // height: 100px !important;
      }
      .react-clock,
      .react-clock *,
      .react-clock *:before,
      .react-clock *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
   
      .react-clock__face {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid $color-grey-4;
        border-radius: 50%;
      }
   
      .react-clock__hand {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        right: 50%;
      }
   
      .react-clock__hand__body {
        position: absolute;
        transform: translate(-50%);
      }
   
      .react-clock__mark {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        right: 50%;
      }
   
      // .react-clock__mark__body {
      //     position: absolute;
      //     background-color: #000;
      //     transform: translate(-50%)
      // }
   
      .react-clock__mark__number {
        position: absolute;
        left: -40px;
        width: 80px;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
      }
      .react-clock__hand__body .react-clock__hour-hand__body {
        width: 3px;
        top: 31%;
        bottom: 41%;
      }
   
      .white-clock {
        background-color: $color-white;
        border-radius: 50%;
   
        .react-clock__mark__number {
          color: $color-black;
        }
        .react-clock__hand__body {
          background-color: $color-black;
        }
        .react-clock__hour-hand__body {
          width: 3px !important;
          top: 27% !important;
          bottom: 44% !important;
        }
        .react-clock__minute-hand__body {
          width: 2px !important;
          top: 20% !important;
          bottom: 44% !important;
        }
        .react-clock__second-hand__body {
          width: 2px !important;
          top: 10% !important;
          background-color: $color-orange;
        }
      }
      .grey-clock {
        background-color: $color-dark-grey;
        border-radius: 50%;
        .react-clock__mark__number {
          color: $color-white;
        }
        .react-clock__hand__body {
          background-color: $color-white;
        }
        .react-clock__hour-hand__body {
          width: 3px !important;
          top: 27% !important;
          bottom: 44% !important;
        }
        .react-clock__minute-hand__body {
          width: 2px !important;
          top: 20% !important;
          bottom: 44% !important;
        }
        .react-clock__second-hand__body {
          width: 2px !important;
          top: 10% !important;
          background-color: $color-orange;
        }
      }
   
      // .react-clock__face {
      //     border: 3px solid white
      // }
   
      .react-clock__second-hand {
        transition: transform cubic-bezier(0.68, 0, 0.27, 1.55) 0.2s;
      }
   
      // .react-clock__second-hand__body:before {
      //     content: "";
      //     display: block;
      //     width: 7px;
      //     height: 7px;
      //     position: absolute;
      //     bottom: 20%;
      //     left: 50%;
      //     background-color: red;
      //     border-radius: 50%;
      //     transform: translate(-50%) translateY(-50%)
      // }
   
      // .react-clock__second-hand__body:after {
      //     content: "";
      //     display: block;
      //     width: 20px;
      //     height: 20px;
      //     position: absolute;
      //     top: 0;
      //     left: 50%;
      //     background-color: red;
      //     border-radius: 50%;
      //     transform: translate(-50%)
      // }
    }
  }