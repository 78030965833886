.card-container {
  background: $defaultBackground;
  padding-bottom: 32px;
  &__area {
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 32px;
    position: relative;
    &__head {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 12px;
      &__title {
        font-family: Inter;
        font-size: 32px;
        font-weight: 600;
        line-height: 39px;
        color: $color-black;
        margin: 0;
        &.active {
          background: -webkit-linear-gradient(0deg, #0000a0 0%, #0094d3 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          width: fit-content;
        }
      }
      &__subtitle {
        font-family: Inter;
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        color: $color-grey-1;
        &__btn {
          background: unset;
          width: 18px;
          height: 18px;
          border: unset;
          padding: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &__body {
      // display: inline-flex;
      // flex-wrap: wrap;
      // gap: 24px;
      position: relative;
      &__card-slider {
        position: unset;
        .slick-track {
          display: flex;
          width: 100% !important;
        }
        .slick-slide {
          padding-right: 20px;
        }
        .slick-arrow {
          position: absolute;
          left: unset !important;
          top: -38px;
          background-image: url("../../assets/images/slider_arrow.svg");
          background-size: cover; /* or contain, or a specific size */
          background-repeat: no-repeat; /* or repeat-x, repeat-y, or repeat */
          background-position: center center; /* or top, bottom, left, right, etc. */
          height: 16px;
          width: 18px;
          &.slick-prev {
            right: 50px;
            transform: scaleX(-1);
            &::before {
              display: none;
            }
          }
          &.slick-next {
            right: 16px;
            transform: scaleY(1);
            &::before {
              display: none;
            }
          }
          &.slick-prev:hover,
          &.slick-prev:focus,
          &.slick-next:hover,
          &.slick-next:focus {
            opacity: 1;
          }

          &.slick-prev.slick-disabled,
          &.slick-next.slick-disabled {
            opacity: 0.25;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    &__area {
      padding: 32px 30px 0;
    }
  }
  @media screen and (max-width: 900px) {
    &__area {
      padding: 50px 0 0 60px;
      &__head {
        gap: 8px;
        &__title,
        &__subtitle {
          font-size: 17px;
          &__btn {
            width: 12px;
            height: 12px;
          }
        }
      }
      &__body__card-slider .slick-arrow {
        width: 14px;
        height: 12px;
        &.slick-prev {
          right: 70px;
        }
        &.slick-next {
          right: 40px;
        }
      }
    }
  }
  @media screen and (max-width: 680px) {
    &__area__body__card-slider .slick-arrow {
      &.slick-prev {
        right: 50px;
      }
      &.slick-next {
        right: 25px;
      }
    }

    &__area {
      padding: 10px 0 0 25px;
    }
  }
}
