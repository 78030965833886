.news-container {
  // background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  //   linear-gradient(
  //     182.14deg,
  //     rgba(255, 255, 255, 0) 1.8%,
  //     rgba(0, 0, 0, 0.05) 14.25%,
  //     rgba(0, 0, 0, 0.69) 54.72%
  //   ),
  //   url("../../../assets/images/nordea-news-banner.png") no-repeat center;
  background-size: cover;
  // transition: transform 0.3s ease-in-out;
  // transform: scale(1);
  padding: 0;
  background: radial-gradient(97.95% 97.95% at 52.8% 2.05%, rgba(255, 255, 255, 0) 0%, #DAE7F1 100%), linear-gradient(153.14deg, #EFF8FF 16.12%, #F1D7D6 99.53%);
  box-shadow: 0px 0px 10px rgba(139, 139, 139, 0.12);


  .common-card__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    cursor: pointer;

    .news-banner{
      width: 169px;
      height: 150px;
    }
    .news-card {
      // margin-top: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 13px;
      align-items: end;
      &__tags__title {
        background: rgba(0, 0, 0, 0.1);
        font-size: 12px;
        font-weight: 500;
        color: $color-black;
        padding: 6px 12px;
        border-radius: 24px;
        line-height: 20px;
      }
      &__header {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        color: $color-black;
        margin-top: 12px;
      }
      &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        &__date,
        &__readmore {
          font-size: 12px;
          font-weight: 400;
          line-height: 14.52px;
          color: $color-black;
        }
      }
    }
  }
}
// .news-container:hover {
//   transform: scale(1.05);
// }
