.cds--dropdown {
  height: inherit;
  width: inherit;
  .cds--list-box__field:focus {
    outline: unset;
  }
  .cds--list-box__menu li {
    border-block-end: 1px solid transparent;
    border-block-start: 1px solid transparent;
  }
}
