.header-content {
  width: 100%;
  // height: 201px;
  background: url("../../../assets/images/banner.png");
  &__headings {
    h1 {
      display: flex;
      justify-content: center;
      font-size: 64px;
      font-weight: 500;
      color: white;
      text-align: center;
      padding-top: 26px;
      padding-bottom: 62px;
      margin: 0;
    }
  }
}

/** COMPANY - S-ASSA **/
.s-assa-frame {
  align-items: center;
  background-color: $color-white;
  display: inline-flex;
  flex-direction: column;
  gap: 48px;
  position: relative;

  .overlap-group-wrapper {
    height: 317px;
    position: relative;

    .overlap-group-2 {
      height: 317px;
      position: relative;
      display: flex;
      justify-content: center;

      .frame-wrapper {
        align-items: flex-start;
        display: inline-flex;
        flex-direction: column;
        left: 0;
        top: 0;
        img {
          width: 100%;
        }
      }

      .header {
        background-color: rgba(249, 249, 249, 1);
        height: 77px;
        position: absolute;
        top: 23px;
        width: 98%;

        .header-container {
          display: flex;
          align-items: center;
          height: 77px;
          padding: 0 30px;
          .menu {
            align-items: flex-start;
            display: inline-flex;
            gap: 29px;
            left: 30px;
            // position: absolute;
            top: 28px;
            width: 40%;

            &-container {
              align-items: center;
              display: inline-flex;
              flex: 0 0 auto;
              gap: 6px;
              position: relative;
              &-text {
                color: $color-black;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: normal;
                margin-top: -1px;
                position: relative;
                width: fit-content;
              }
            }
          }
          .logo {
            width: 40%;
            display: inline-flex;
            height: 26px;
            padding-left: 100px;
          }
          .search {
            align-items: center;
            border-radius: 24px;
            display: inline-flex;
            gap: 150px;
            padding: 8px 24px;
            top: 18px;
            width: 20%;
            border-radius: 24px;
            background: $color-grey-6;
            justify-content: space-between;

            .text-wrapper-19 {
              color: $color-grey-3;
              font-size: 15px;
              font-weight: 400;
              letter-spacing: 0;
              line-height: normal;
              position: relative;
              width: fit-content;
            }
          }
        }
      }
    }
  }
}

/** COMPANY - S-NDA **/
.s-nda-frame {
  .overlap-group-wrapper {
    // border-bottom: 1px solid $color-grey-5;
    .header {
      margin: 0 auto;
      max-width: 1440px;
      position: relative;
      padding: 25px 0;
      .header-container {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // gap: 50px;
        .logo {
          display: inline-flex;
          align-items: center;
          gap: 24px;
          &-content{
            display: inline-flex;
            align-items: center;
            gap: 12px;
            span {
              font-size: 32px;
              font-weight: 500;
              color: $color-grey-1;
            }
          }          
          .companyFollow{
            font-size: 18px;
            font-weight: 500;
            color: $color-grey-2;
            background: unset;
            border: unset;
            cursor: pointer;
          }
          
        }
        .hamburger-logo {
          display: none;
        }
        .menu {
          align-items: flex-start;
          display: inline-flex;
          gap: 29px;

          &-container {
            align-items: center;
            display: inline-flex;
            flex: 0 0 auto;
            gap: 6px;
            position: relative;
            &-text {
              color: $color-primary-nordea;
              font-size: 15px;
              font-weight: 400;
              letter-spacing: 0;
              line-height: normal;
              margin-top: -1px;
              position: relative;
              width: fit-content;
            }
          }
        }
        &__navPanel {
          box-shadow: 0 4px 6px -2px rgba(46, 46, 46, 0.25);
          margin-bottom: 28px;
          &__detail {
            display: flex;
            justify-content: space-between;
            padding: 24px 20px;
            margin: 0 auto;
            max-width: 1440px;
            align-items: center;
            &__title {
              p {
                font-size: 30px;
                font-weight: 600;
                line-height: 43px;
                color: $color-grey-1;
              }
            }
            &__content {
              display: inline-flex;
              justify-content: space-between;
              align-items: center;
              gap: 32px;
              &-ticker {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                gap: 24px;
                .analogClock-container {
                  gap: 24px;
                }
                .analogClock-container::after {
                  align-self: stretch;
                  content: "";
                  border: 1px solid $color-grey-4;
                }
                .react-clock {
                  width: 80px !important;
                  height: 80px !important;
                }
                .ticker-container-wraper {
                  max-width: 299px;
                }
              }
              &-btn {
                display: inline-flex;
                align-items: center;
                gap: 12px;
                button {
                  // border: unset;
                  font-size: 16px;
                  font-weight: 500;
                  cursor: pointer;
                }
                &-login {
                  padding: 10px 16px;
                  // background-color: $color-grey-5;
                  background: transparent;
                  border: 1px solid $color-grey-4;
                  border-radius: 4px;
                  color: $color-grey-2;
                }
                &-signup {
                  padding: 10px 16px;
                  background-color: $color-primary-nordea;
                  border: 1px solid $color-primary-nordea;
                  color: $color-white;
                  border-radius: 4px;
                }

                .header-user-info {
                  display: flex;
                  flex-direction: row;
                  column-gap: 8px;
                  align-items: center;
                  position: relative;
                  .user-info {
                    
                    &__profile-pic {
                      width: 44px;
                      text-align: center;
                      border-radius: 22px;
                      // border: 1px solid $color-primary-nordea;
                      height: 44px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      // background: var(--color-primary-nordea);
                      img {
                        max-width: 44px;
                        border-radius: 22px;
                      }
                      span{
                        color: $color-white;
                        font-size: 19px;
                        text-align: center;
                        height: 44px;
                        width: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                      }
                      cursor: pointer;
                    }
                  }
                  
                  .dropdown-menu {
                    position: absolute;
                    top: 50px;
                    right: 32px;
                    background-color: $color-white;
                    border: 1px solid #ddd;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
                    z-index: 1000;
                    width: auto;
                    padding: 10px;
                    span{
                      padding: 10px;
                      cursor: pointer;
                    }
                  }
                }
                
              }
            }
          }
        }
      }
    }
  }
}

.header-tab {
    background: $color-grey-6;
    &__list {
      margin: 0 auto;
      max-width: 1440px;

      @media screen and (max-width: 1440px) {
        padding: 0 30px;
      }
      @media screen and (max-width: 900px) {
        padding: 0 60px;
      }
      @media screen and (max-width: 680px) {
        padding: 0 25px;
      }
      .cds--tabs__nav-link {
        font-size: 15px;
        line-height: 18.15px;
        padding-inline: unset;
        border-block-end: unset;
        color: $color-grey-2;
      }
      .cds--tab--list {
        gap: 60px;
        height: 54px;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-size: 15px;
          line-height: 18.15px;
          padding-inline: unset;
          border-block-end: unset;
          color: $color-grey-2;
        }
        .active{
          border-block-end: 4px solid $color-grey-2 !important;
          font-weight: 700;
          padding-top: 4px;
        }
      }
      .cds--tabs__nav-item--selected {
        border-block-end: 4px solid $color-grey-2 !important;
        font-weight: 700;
        padding-top: 4px;
      }
      &:not(.cds--tabs--contained)
        .cds--tabs__nav-item:not(.cds--tabs__nav-item--selected):not(
          .cds--tabs__nav-item--disabled
        ):hover {
        border-block-end: unset !important;
      }
      .cds--tabs__nav-link:focus {
        outline: unset !important;
      }
    }
  }

@media screen and (max-width: 900px) {
  .s-nda-frame .overlap-group-wrapper {
    border-bottom: unset;
    .header {
      margin: 25px 60px 0;
      border-bottom: 1px solid var(--color-grey-5);
      padding: 25px 0;
      .header-container {
        .logo {
          height: 32px;
          // width: 150px;
          gap: 18px;
        }
        .hamburger-logo {
          display: block;
          height: 32px;
          width: 32px;
        }
        .menu {
          display: none;
        }
        &__navPanel {
          &__detail {
            &__content {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .s-nda-frame {
    .overlap-group-wrapper {
      .header {
        margin: 10px 25px 0;
        .header-container {
          .logo {
            &-content{
              span {
                font-size: 24px;
              }
            }
            .companyFollow{
              display: none;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .s-nda-frame {
    .overlap-group-wrapper {
      .header {
        .header-container {
          .logo {
            flex-wrap: wrap;
            gap: 4px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .s-nda-frame {
    .overlap-group-wrapper {
      .header {
    padding: 25px 30px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .s-nda-frame {
    .overlap-group-wrapper {
      .header {
        padding: 25px 0;
      }
    }
  }
}