.login-grid {
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &-row {
    display: flex;
    width: 1020px;
    min-height: 600px;
    // height: 769px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
    &-left {
      // background-color: #D8DDF1;
      // background: url(../../assets/images/login-banner-bg.svg) #D8DDF1;
      margin: 0;
      // display: flex;

      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      &-container {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          background: $color-white;
        }
      }
    }
    &-right {
      flex: 1;
      background-color: $color-white;
      margin: 0;
      &-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        color: $color-grey-2;
        padding: 25px;
        h4 {
          font-size: 24px;
          margin: 0;
          text-align: center;
          color: $color-grey-1;
        }
        .welcome-content {
          font-size: 17px;
          font-weight: 400;
          color: $color-grey-1;
          margin-top: 18px;
          text-align: center;
        }
        &-form {
          margin: 18px 0 24px 0;
          input {
            background-color: $defaultBackground;
            color: $color-grey-3;
          }
          .email-container {
            position: relative;
            img {
              position: absolute;
              top: 11px;
              right: 10px;
            }
          }
          .forgotPassword_button {
            border: none;
            outline: 0;
            background-color: transparent;
            color: #1877f2;
            margin-top: 2%;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;
          }
          .cds--text-input:focus {
            outline: none;
            border-left: 2px solid #1877f2;
            border-right: 2px solid #1877f2;
          }
          .password-container {
            .text-row {
              display: flex;
              gap: 15px;
              margin-top: 8px;
              p {
                font-size: 12px;
                font-weight: 500;
                color: #828282;
              }
            }
          }
          .error-text {
            font-size: 12px;
            color: #eb5757;
            font-weight: 500;
            margin-top: 7px;
          }
          .create-account-button {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              width: 100%;
              align-items: center;
              justify-content: center;
              padding: 0;
              flex: 1;
              max-width: 100%;
            }
          }
          .back_signin_button {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 5%;
            button {
              border: none;
              outline: 0;
              background-color: transparent;
              font-size: 16px;
              color: #1877f2;
              font-weight: 500;
              cursor: pointer;
            }
          }
          .forgot-password-Container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .hederText {
              padding-top: 5%;
              padding-bottom: 5%;
            }
            .content_text {
              text-align: center;
              line-height: 1.9;
            }
          }
          .eye_icons_position {
            position: absolute;
            top: 11px;
            right: 5px;
            button {
              border: 0;
              outline: none;
              cursor: pointer;
              background-color: transparent;
            }
          }
        }
        &-alternative {
          text-align: center;
          color: $color-grey-3;
          p {
            font-size: 15px;
            font-weight: 400;
            border-bottom: 1px solid $color-grey-3;
            line-height: 0.1em;
            margin: 10px 0 20px;
            span {
              background: $color-white;
              padding: 0 10px;
            }
          }
          button {
            font-size: 15px;
            font-weight: 400;
            margin: 0.5rem;
            color: #1877f2;
            align-items: center;
            padding: 13px 11px;
            width: 124px;
            border-color: 1px solid #1877f2;
            [class^="icon-"] {
              font-size: 24px;
            }
          }
        }
        &-existinguser {
          font-size: 17px;
          text-align: center;
          padding: 24px 0;
          color: $color-grey-2;
          * {
            color: $color-grey-2;
            padding: 0 5px;
          }
        }
      }
    }
  }
}
