.earnings-tab {
  &__tab {
    min-block-size: 26px;
    // margin-bottom: 12px;
    .cds--tabs__nav-item,
    .cds--tabs__nav-link {
      font-size: 12px;
      font-weight: 500;
      // line-height: 19px;
      letter-spacing: 0;
      color: $color-grey-2 !important;
      flex: unset;
      width: 80px;
      .cds--tabs__nav-item-label-wrapper {
        align-items: center;
        justify-content: center;
      }
    }
    .cds--tabs__nav-item--selected {
      font-weight: 600;
      background: $color-grey-1;
      color: $color-white !important;
    }
    .cds--tabs__nav-link,
    .cds--tabs__nav-item--selected,
    .cds--tabs__nav-link:focus,
    .cds--tabs__nav-link:active,
    .cds--tabs__nav-item:not(.cds--tabs__nav-item--selected):not(
        .cds--tabs__nav-item--disabled
      ):hover {
      color: inherit;
      border-block-end: unset !important;
      outline: unset !important;
    }
    .cds--tab--list {
      border: 1px solid $color-grey-5;
      border-radius: 4px;
    }

    .cds--tabs__nav-item {
      position: relative;
    }
    .cds--tabs__nav-item:not(.cds--tabs__nav-item--selected)::after {
      content: "";
      position: absolute;
      border-left: 1px solid $color-grey-2;
      height: 50%;
      top: 6px;
      left: 0;
    }
    .cds--tabs__nav-item--selected + .cds--tabs__nav-item::after,
    .cds--tabs__nav-item:first-child::after {
      display: none;
    }
  }
  &__list {
    &:nth-child(2) {
      padding-top: 5px;
    }
    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 10px 0;
      &__title {
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        color: $color-grey-1;
        &__sub {
          font-weight: 400;
        }
      }
      &__schedule {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: $color-grey-1;
      }
    }
    &__details {
      border: 1px solid $color-grey-5;
      border-bottom: unset;
      border-radius: 5px;
      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $color-grey-5;
        padding: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: $color-grey-2;
        &__value {
          font-weight: 500;
          &.false {
            color: $color-grey-1;
          }
        }
      }
    }
    &.cds--tab-content {
      padding: 0;
      &:focus {
        outline: unset;
      }
    }
  }
}