.q-a-container {
  .common-card__list{
    display: flex;
    height: 100%;
    .qa-card{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      // cursor: pointer;
      &__top {
        display: flex;
        flex-direction: column;
        gap: 14px;
        &__header {
          font-size: 17px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 2;
          color: $color-grey-1;
        }
        &__date {
          font-size: 12px;
          font-weight: 400;
          color: $color-grey-2;
        }
      }
      &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-top: 12px;
        &__tags {
          font-size: 10px;
          font-weight: 500;
          line-height: 10.7px;
          color: $color-grey-1;
          background-color: $color-grey-6;
          padding: 4px 8px;
          border-radius: 18px;
        }
      }
    }
  }
}
