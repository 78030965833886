.dividend-donut-bar-chart {
  &__donutBarLarge,
  &__donutLarge {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__donutBarLarge {
    justify-content: unset;
    .donut-chart,
    .dividend-donut-chart {
      width: 340px;
    }
    .bar-line-chart__title {
      margin: 0 28px 3px 10px;
    }
  }
  &__donutLarge {
    .donut-chart {
      width: 340px;
    }
    &__info {
      width: 340px;
      padding-right: 60px;
      font-weight: 500;
      letter-spacing: 0;
      &__last {
        font-size: 16px;
        line-height: 24px;
        color: $color-grey-3;
      }
      &__lastValue {
        font-size: 35px;
        line-height: 51px;
        color: $color-grey-1;
        padding: 10px 0;
      }
      &__yieldArea {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        padding-bottom: 10px;
        &__lbl {
          color: $color-grey-3;
        }
        &__lblValue {
          color: $color-grey-1;
        }
      }
    }
  }
}
