.data-source__dropdown .cds--dropdown {
  width: 154px;
  height: 28px;
  background: inherit;

  .cds--list-box__label {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0;
    color: $color-grey-2;
  }
  .cds--list-box__field {
    padding-left: 5px;
    padding-right: 20px;
  }
  .cds--list-box__menu-icon {
    right: 0;
    svg {
      fill: $color-grey-2;
    }
  }
  .cds--list-box__menu-item,
  .cds--list-box__menu-item:active,
  // .cds--list-box__menu-item:focus,
  .cds--list-box__menu-item__option {
    border-block-start-style: unset;
    border-block-end-style: unset;
    border: unset;
    background: $color-white;
    padding-right: 0;
    outline: unset;
  }
  .cds--list-box__menu-item__option {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: $color-grey-2;
    margin-right: 0;
    margin-left: 10px;
    height: 35px;
  }
  .cds--list-box__menu-item__selected-icon {
    display: none;
  }
}
