.trend-chart {
  &__body {
    display: inline-flex;
    gap: 12px;
    &__card {
      background: $color-grey-6;
      border-radius: 12px;
      width: 340px;
      padding: 10px;
      height: 280px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__duration {
        font-size: 16px;
        font-weight: 500;
        line-height: 24.2px;
        text-align: right;
        color: $color-grey-3;
      }
      &__footer {
        display: flex;
        justify-content: space-between;
        &__trend {
          font-size: 15px;
          font-weight: 500;
          color: $color-grey-2;
          text-transform: uppercase;
        }
        &__val {
          font-size: 17px;
          font-weight: 600;
          color: $color-grey-2;
        }
      }
    }
  }
}
