.tile-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 7%;
}
.announcement-detail {
  margin: 0 30px;
  &-panel {
    background: unset;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 12px;
    &-title {
      font-size: 24px;
      line-height: 29px;
      font-weight: 500;
      color: $color-grey-1;
      padding-top: 20px;
    }
    &-categories {
      display: flex;
      gap: 10px;
      &-list {
        font-size: 13px;
        font-weight: 400;
        line-height: 15.73px;
        color: $color-grey-2;
        padding: 8px 16px;
        border: 1px solid $color-grey-5;
        border-radius: 24px;
      }
    }
    &-activity {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $color-grey-5;
      border-bottom: 1px solid $color-grey-5;
      padding: 18px 0;
      &-createdOn {
        font-size: 15px;
        font-weight: 400;
        line-height: 18.15px;
        color: $color-grey-2;
        &__dot {
          font-size: 17px;
          font-weight: 200;
          color: $color-grey-1;
        }
      }
      &-history {
        display: inline-flex;
        gap: 24px;
        align-items: center;
        &-content {
          display: inline-flex;
          align-items: center;
          gap: 6px;
          font-size: 15px;
          font-weight: 400;
          img {
            width: 18px;
            height: 15px;
          }
        }
      }
    }
    &-content {
      // max-height: 650px;
      overflow: hidden;
      overflow-y: auto;
      padding-right: 10px;
      p,
      span,
      a,
      button,
      li,
      th,
      td {
        font-family: "Inter" !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
        // text-align: justify;
        color: $color-grey-1;
      }
      mark {
        background-color: #feffd5;
      }
      &::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background-color: $color-grey-5;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $color-grey-5;
        border-radius: 10px;
        background-color: $color-grey-5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px $color-grey-1;
        background-color: $color-grey-3;
      }
      .highlighted {
        background-color: #feffd4;
      }

      /* Override styles for headings */
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: "Inter" !important;
        color: $color-grey-1 !important;
      }

      /* Override link styles */
      a {
        color: #007bff !important;
        text-decoration: none !important;
      }

      a:hover {
        text-decoration: underline !important;
      }

      /* Override button styles */
      button {
        background-color: #007bff !important;
        color: $color-white !important;
        border: none !important;
        padding: 10px 20px !important;
        cursor: pointer !important;
      }

      /* Override form elements */
      input,
      textarea,
      select {
        border: 1px solid $color-grey-5 !important;
        padding: 10px !important;
        font-size: 16px !important;
      }

      /* Override list styles */
      ul,
      ol {
        margin: 0 !important;
        padding: 0 !important;
        list-style: none !important;
      }

      li {
        margin-bottom: 10px !important;
      }

      /* Override table styles */
      table {
        width: 100% !important;
        border-collapse: collapse !important;
      }

      th,
      td {
        border: 1px solid $color-grey-5 !important;
        padding: 8px !important;
        text-align: left !important;
      }

      /* Override image styles */
      img {
        max-width: 100% !important;
        height: auto !important;
      }
    }
  }
  &-question-panel {
    background: unset;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid $color-grey-5;
    border-radius: 4px;

    &-post {
      display: flex;
      gap: 12px;
      flex-direction: column;
      &-highlight {
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        padding: 18px;
        border-radius: 4px;
        border: 1px solid $color-grey-5;
        text-align: justify;
        span {
          background-color: #feffd4;
        }
      }
      .cds--form-item {
        .cds--text-area__wrapper {
          .cds--text-area {
            background-color: $color-white;
            border: 1px solid $color-grey-5;
          }
          :active {
            outline: unset;
          }
        }
      }
      &-content {
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        background-color: $color-white;
        color: $color-grey-2;
        border-radius: 4px;
      }
      &-action {
        display: flex;
        gap: 10px;
        justify-content: end;
        &-cancel {
          padding: 5px 10px;
          background: transparent;
          border: 1px solid $color-grey-4;
          border-radius: 4px;
          color: $color-grey-2;
          min-width: 60px;
          cursor: pointer;
        }
        &-submit {
          padding: 5px 10px;
          background: $color-primary-nordea;
          border: 1px solid $color-grey-4;
          border-radius: 4px;
          color: $color-white;
          min-width: 60px;
          cursor: pointer;
        }
      }
    }

    &-highlight {
      display: flex;
      gap: 12px;
      flex-direction: column;
      &-content {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        padding: 18px 24px;
        border-radius: 4px;
        border: 1px solid $color-grey-5;
        span {
          background-color: #feffd4;
          cursor: pointer;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &.active {
        margin-top: 12px;
      }
      .title {
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
        color: $color-grey-1;
        border-bottom: 1px solid $color-grey-5;
        padding-bottom: 16px;
      }
      .content {
        display: flex;
        gap: 12px;
        flex-direction: column;
        color: $color-grey-2;

        .posted-data {
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          text-align: justify;
        }
        .bold {
          font-weight: 600;
        }
      }
      &-footer {
        display: flex;
        justify-content: space-between;
        .found-helpful {
          font-size: 15px;
          font-weight: 400;
          color: $color-grey-2;
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
          }
          sup {
            font-weight: 500;
          }
        }
        .make-reply {
          background: unset;
          border: unset;
          font-size: 15px;
          font-weight: 400;
          color: $color-grey-2;
          cursor: pointer;
        }
      }
    }
  }

  .reply-panel {
    border: unset;
    padding: 16px 0;
    .textarea-container {
      position: relative;
      display: inline-block;
      width: 90%;
      .icon-close {
        position: absolute;
        right: 10px;
        top: 14px;
        // transform: translateY(-50%);
        cursor: pointer;
        font-size: 14px;
        color: #999;
      }
    }
    .cds--form-item {
      .cds--text-area__wrapper {
        .cds--text-area {
          height: 44px;
          border-radius: 10px;
          background: var(--default-background) !important;
        }
        :active {
          outline: unset;
        }
      }
    }
  }

  .collapsible-tile {
    background-color: unset;
    border: 1px solid $color-grey-5;
    .collapsible-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .title {
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
        color: $color-grey-1;
      }
      .expand-icon {
        background-color: unset;
        border: unset;
        border: 1px solid $color-grey-5;
        border-radius: 4px;
        font-size: 18px;
      }
    }
  }
  &-post-question {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 1px solid $color-grey-5;
    background: $defaultBackground;
    padding: 0 6px;
    &-input {
      width: 100%;
      border: unset;
      padding: 14px 7px;
      background: $defaultBackground;
      &:focus-visible {
        outline: unset;
      }
    }
    &-submit {
      background: $color-grey-1;
      color: $color-white;
      border: unset;
      width: 82px;
      height: 34px;
      border-radius: 4px;
      cursor: pointer;
    }

    &-guest {
      padding: 14px 7px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: $color-primary-nordea;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }
    }
  }
}
.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  &-profile {
    width: 42px;
    height: 42px;
    border-radius: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-pic {
      color: $color-white;
      font-size: 19px;
      text-align: center;
      height: 42px;
      width: 42px;
      border-radius: 21px;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
  &-details {
    display: inline-flex;
    flex-direction: column;
    gap: 6px;
    &-name {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: $color-grey-2;
    }
    &-postedOn {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: $color-grey-3;
    }
  }
}
.breadcrumb {
  padding: 12px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: $color-grey-2;
  a {
    text-decoration: none;
    color: $color-grey-2;
  }
  &__span {
    color: #0f62fe;
  }
}

.context-menu {
  position: fixed;
  z-index: 1000;
  background-color: $color-grey-1;
  padding: 10px;
  display: flex;
  gap: 14px;
  border-radius: 4px;
  img {
    cursor: pointer;
  }
}

.context-menu-option {
  padding: 8px 16px;
  cursor: pointer;
}

.context-menu-option:hover {
  background-color: #f0f0f0;
}

.context-menu-tooltip {
  position: fixed;
  z-index: 1000;
  border: 1px solid $color-black;
  color: $color-black;
  padding: 4px 8px;
  font-size: 12px;
  background: $color-white;
}

.announcement-qa-container {
  // max-height: 820px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    background-color: $color-grey-5;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $color-grey-5;
    border-radius: 10px;
    background-color: $color-grey-5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px $color-grey-1;
    background-color: $color-grey-3;
  }
}
