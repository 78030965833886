@import "./announcement-card.scss";
@import "./document-card.scss";
@import "./video-card.scss";
@import "./audio-card.scss";
@import "./earnings-tab.scss";

.common-card {
  font-family: Inter;
  width: 342px;
  // max-width: 342px;
  height: 342px;
  padding: 13px;
  box-shadow: 0px 0px 10px 0px #8b8b8b1f;
  background: $color-white;
  &__head {
    font-size: 19px;
    font-weight: 600;
    line-height: 20.57px;
    color: $color-grey-1;
    // border-bottom: 1px solid $color-grey-5;
    padding-bottom: 14px;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__company {
      font-weight: 600;
    }
    &__new {
      font-family: Inter;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      padding: 2px 4px;
      border-radius: 4px;
      background: #ff4747;
      color: $color-white;
      margin-left: 10px;
    }
    &__more {
      font-size: 12px;
      font-weight: 400;
      color: $color-blue;
      background: unset;
      border: unset;
      cursor: pointer;
    }
  }
  &__header {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color: $color-grey-3;
    margin-bottom: 10px;
  }
  &__footer {
    float: right;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 15px;
    color: $color-grey-1;
  }

  &__list {
    .audio-card {
      margin: 10px 0;
    }
    .announcement-card:first-child {
      border-bottom: 1px solid $color-grey-5;
    }
    .announcement-card:nth-child(2) {
      margin-top: 10px;
    }
    .cds--tab-content {
      padding: 0;
    }
  }
  @media screen and (max-width: 900px) {
    &__head {
      font-size: 15px;
    }
  }
}
.last-dividend-paid-container .common-card__head div {
  font-weight: 600;
}
.documents-container .common-card__head div {
  font-weight: 600;
  font-size: 19px;
}
.common-card-skeleton{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &-box{
    width: 342px;
    height: 342px;
  }
}