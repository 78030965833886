.dividend-donut-chart {
  &__main {
    // height: 300px;
  }
  &__last {
    width: 100%;
    &__header {
      display: flex;
      // padding-left: 5px;
      &__lbl {
        flex: 1;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        color: $color-grey-3;
      }
    }
    &__value {
      display: flex;
      padding: 6px 0;
      &__lbl {
        flex: 1;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0;
        color: $color-grey-1;
      }
    }
  }
}
