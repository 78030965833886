.document-card {
  &__tab {
    min-block-size: 26px;
    margin-bottom: 18px;
    .cds--tabs__nav-item,
    .cds--tabs__nav-link {
      font-size: 12px;
      font-weight: 400;
      // line-height: 19px;
      letter-spacing: 0;
      color: $color-grey-2 !important;
      flex: unset;
      padding-inline: 14px;
      .cds--tabs__nav-item-label-wrapper {
        align-items: center;
        justify-content: center;
      }
    }
    .cds--tabs__nav-item--selected {
      font-weight: 600;
      background: $color-grey-1;
      color: $color-white !important;
    }
    .cds--tabs__nav-link,
    .cds--tabs__nav-item--selected,
    .cds--tabs__nav-link:focus,
    .cds--tabs__nav-link:active,
    .cds--tabs__nav-item:not(.cds--tabs__nav-item--selected):not(
        .cds--tabs__nav-item--disabled
      ):hover {
      color: inherit;
      border-block-end: unset !important;
      outline: unset !important;
      height: 26px !important;
    }
    .cds--tab--list {
      border: 1px solid $color-grey-5;
      border-radius: 4px;
    }
  }
  &__body {
    &__list {
      border-bottom: 1px solid $color-grey-6;
      &__border_disabled {
        border: none;
      }
      &__details {
        display: flex;
        gap: 10px;
        padding: 12px 0;
        align-items: center;
        &__img {
          height: 24px;
          width: 24px;
        }
        &__content {
          color: $color-grey-1;
          &__name {
            font-size: 12px;
            font-weight: 500;
            line-height: 14.52px;
          }
          &__date {
            font-size: 10.5px;
            line-height: 13.27px;
            margin-top: 5px;
          }
        }
        &__read {
          font-family: "Inter";
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: $color-grey-3;
        }
      }
      &.cds--tab-content {
        padding: 0;
        &:focus {
          outline: unset;
        }
      }
    }
  }
}
