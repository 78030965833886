$icomoon-font-family: "wise" !default;
$icomoon-font-path: "fonts" !default;

$icon-euroland-ir-path1: unquote('"\\e900"');
$icon-euroland-ir-path2: unquote('"\\e901"');
$icon-euroland-ir-path3: unquote('"\\e902"');
$icon-euroland-ir-path4: unquote('"\\e903"');
$icon-social-apple: unquote('"\\e904"');
$icon-social-fb: unquote('"\\e905"');
$icon-social-google: unquote('"\\e906"');
$icon-close: unquote('"\\e941"');
$icon-chevron-thin-down: unquote('"\\e943"');
$icon-chevron-thin-right: unquote('"\\e944"');

