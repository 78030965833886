.doubleBarWithTableXl-chart {
  &__bar {
    .doubleBarXl-chart__forecast {
      &__bg {
        height: 93px !important;
        margin-top: 0 !important;
      }
      // &__txt {
      //   top: 38px !important;
      //   right: 16%;
      // }
    }
  }
  &__data {
    &__table {
      td:not(:first-child),
      th:not(:first-child) .cds--table-header-label {
        text-align: right !important;
      }
      thead {
        background: $color-grey-5;
        th .cds--table-header-label {
          color: $color-grey-3;
          font-size: 12px;
          line-height: 14.52px;
          font-weight: 400;
          padding-block-end: 5px;
          padding-block-start: 5px;
        }
        th {
          padding-inline-end: 15px;
          padding-inline-start: 10px;
          &:first-child {
            padding-inline-start: 10px;
          }
        }
      }
      tbody tr {
        font-size: 12px;
        line-height: 14.52px;
        font-weight: 500;
        background: $color-grey-6 !important;
        color: $color-grey-2;
        position: relative;
        td {
          padding-block-end: 5px;
          padding-block-start: 5px;
          padding-inline-end: 16px;
          padding-inline-start: 10px;
          color: $color-grey-2;
        }
        &:first-child td:first-child::before,
        &:nth-child(2) td:first-child::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 32px;
          width: 5px;
        }
        &:first-child td:first-child::before {
          background: #a7a7eb !important;
        }
        &:nth-child(2) td:first-child::before {
          background: #4040ab !important;
        }
        &:nth-child(3) td {
          border-bottom: unset !important;
        }
        .positive {
          color: $color-green-2 !important;
        }
        .negative {
          color: $color-red !important;
        }
      }
    }
  }

  .cds--data-table--sm thead tr,
  .cds--data-table--sm tbody tr,
  .cds--data-table--sm tbody tr th {
    block-size: 0;
  }
}
