.analyst-speedometer-chart {
  position: relative;
  &__buy,
  &__outperform,
  &__hold,
  &__underperform,
  &__sell {
    position: absolute;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
    color: $color-grey-2;
  }

  &__table_parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
    justify-content: space-between;
    .table_data {
      display: flex;
      flex-direction: column;
      &__table__header {
        font-size: 14px;
        font-weight: 500;
        line-height: 17.9px;
        // text-align: center;
        color: $color-grey-2;
      }
      &__table__value {
        font-size: 16px;
        font-weight: 600;
        line-height: 20.29px;
        // text-align: center;
        color: $color-grey-1;
        padding: 5px 0;
        text-align: center
      }
    }
  }

  &__render_section {
    pointer-events: none;
  }
  &__buy {
    bottom: 40%;
    right: 10%;
  }
  &__outperform {
    top: 37%;
    right: 5%;
  }
  &__hold {
    top: 24%;
    left: 46%;
  }
  &__underperform {
    top: 37%;
    left: 1%;
  }
  &__sell {
    bottom: 40%;
    left: 10%;
  }
  .highcharts-text-outline {
    stroke: unset !important;
  }
  &__analysts,
  &__footer {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.57px;
    color: $color-grey-1;
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    tr:first-child td {
      font-size: 14px;
      font-weight: 500;
      line-height: 17.9px;
      text-align: center;
      color: $color-grey-2;
      min-width: 52px;
    }
    tr:nth-child(2) td {
      font-size: 16px;
      font-weight: 600;
      line-height: 20.29px;
      text-align: center;
      color: $color-grey-1;
      padding: 5px 0;
    }
  }
  &__footer {
    font-size: 14px;
    line-height: 18.15px;
    padding-top: 5px;
  }
}
.analyst-range-slider {
  &__flex_row_progress_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  p {
    font-size: 14px;
    line-height: 18.15px;
    padding-top: 5px;
    margin-top: 2%;
  }
  &__Strong_buy .cds--progress-bar__bar {
    background-color: #338d33; /* Color of the progress bar */
  }
  &__buy .cds--progress-bar__bar {
    background-color: #4dc94d; /* Color of the progress bar */
  }
  &__Neutral .cds--progress-bar__bar {
    background-color: #e8eb60; /* Color of the progress bar */
  }
  &__Sell .cds--progress-bar__bar {
    background-color: #dda641; /* Color of the progress bar */
  }
  &__Strong_Sell .cds--progress-bar__bar {
    background-color: #eb5915; /* Color of the progress bar */
  }
  &__updated_text {
    font-size: 12px;
    margin-top: 2%;
  }
}
