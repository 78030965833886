.financial-reports-page {
  margin: 0 auto;
  max-width: 1440px;
  padding-top: 24px;
  &__bar {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: $color-grey-2;        
    a{
      text-decoration: none;
      color: $color-grey-2;
    }
    &__span a{
      color: #0f62fe;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    &__title {
      font-size: 32px;
      font-weight: 600;
      line-height: 38.73px;
      color: $color-grey-1;
    }
    &__filter {
      display: flex;
      gap: 15px;
      &__years,
      &__topics,
      &__period {
        .cds--dropdown {
          min-height: 48px;
          width: 224px;
          // border-bottom: 1px solid #8d8d8d;
          box-shadow: 0px -1px 0px 0px #8d8d8d inset;
          .cds--label {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.3px;
            color: $color-grey-2;
          }

          .cds--list-box__label {
            // background-color: #f4f4f4;
            color: $color-grey-1 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 18px !important;
            letter-spacing: 0.16px !important;
          }
          .cds--list-box__field {
            padding: 15px 16px;
            padding-inline-start: 1rem !important;
            padding-inline-end: 2.4rem !important;
          }
          .cds--list-box__menu-item,
        .cds--list-box__menu-item:active,
        // .cds--list-box__menu-item:focus,
        .cds--list-box__menu-item__option {
            background-color: #f4f4f4;
          }
          .cds--list-box__menu-icon {
            right: 10px !important;
          }
          .cds--list-box__menu-icon {
            right: 6px;
            svg {
              fill: $color-grey-2;
            }
          }

          .cds--list-box__menu-item,
      .cds--list-box__menu-item:active,
      // .cds--list-box__menu-item:focus,
      .cds--list-box__menu-item__option {
            border-block-start-style: unset;
            border-block-end-style: unset;
            border: unset;
            background: $color-white;
            padding-right: 0;
            outline: unset;
          }
          .cds--list-box__menu-item__option {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0;
            color: $color-grey-1;
            margin-right: 0;
            margin-left: 1rem;
            height: 35px;
          }
          .cds--list-box__menu-item__selected-icon {
            display: none;
          }
        }
      }
    }
  }
  &__body {
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 50px;
    margin: 40px 0;
    align-items: center;
    width: 100%;
    &__card {
      display: flex;
      min-height: 220px;
      width: 464px;
      gap: 15px;
      &__report {
        height: 287px;
        width: 220px;
        img{
          width: 205px;
          height: 287px;

        }
      }
      &__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 287px;
        &__title {
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
          color: $color-black;
          text-decoration: none;
          cursor: pointer;
        }
        &__release {
          font-size: 15px;
          font-weight: 400;
          line-height: 18.15px;
          color: #333333;
          margin-top: 5px;
        }
        &__types {
          margin-top: auto;
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          padding-bottom: 10px;
          &__cap {
            padding: 6px 12px 6px 12px;
            border-radius: 24px;
            border: 1px solid $color-grey-5;
            color: $color-grey-3;
            font-size: 13px;
            font-weight: 400;
            line-height: 15.73px;
            white-space: nowrap;
          }
        }
        &__footer {
          font-size: 15px;
          font-weight: 400;
          line-height: 18.15px;
          color: $color-grey-2;
          margin: 0;
        }
      }
    }
    .no-records{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40vh;
      width: 100%;
      &-msg{
        font-size: 24px;
        font-weight: 500;
        color: $color-grey-1;
      }
    }
  }
}


@media screen and (max-width: 1440px){
  .financial-reports-page {
    padding: 24px 30px 0px 30px;
  }
}
