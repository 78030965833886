.transcript-doc {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &__card_img {
    margin-top: 1%;
    width: 50%;
    height: 190px;
  }
  &__absolute_title {
    position: absolute;
    color: #fff;
    width: 120px;
    margin-left: -7%;
    margin-bottom: 25px;
  }
  &__read-btn {
    background-color: #fff;
    color: $color-grey-1;
    font-weight: 500;
    font-size: 14px;
    width: 35%;
    padding: 6px;
    margin-top: 12px;
  }
  &__single_card_pwrd_by {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 13px;
    img {
      padding-left: 4px;
    }
  }
  &__event_pwrd_by_text {
    font-size: 11px;
    color: $color-grey-3;
    font-weight: 400;
  }
  &__event_pwrd_by_text span {
    font-size: 12px;
    font-weight: 500;
  }
  &__download_app {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__download_app_text {
    font-size: 12px;
    margin-right: 2%;
    font-weight: 500;
    color: $color-grey-3;
  }
  &__report_title {
    margin-top: 2%;
    padding-bottom: 2%;
  }
  &__date_text {
    color: $color-grey-1;
    font-weight: 500;
    font-size: 12px;
  }
}
