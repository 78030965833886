.share-chart-card,
.share-performance,
.shareholding-chart-card,
.shareholding-chart-large,
.peers-chart,
.peer-comparison-chart-large {
  &__tab {
    .cds--tab-content {
      padding: 0;
      &:focus {
        outline: unset;
      }
    }
    &__scroll {
      max-width: 362px;
      overflow-x: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
      }

      // &::-webkit-scrollbar {
      //   width: 4px;
      //   height: 5px;
      //   background-color: #f5f5f5;
      // }

      // &::-webkit-scrollbar-track {
      //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //   border-radius: 10px;
      //   background-color: #f5f5f5;
      // }

      // &::-webkit-scrollbar-thumb {
      //   border-radius: 10px;
      //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //   background-color: rgb(167, 165, 165);
      // }

      &__list {
        min-block-size: 26px;
        border: 1px solid $color-grey-2;
        border-radius: 4px;
        width: fit-content;
        margin-bottom: 1px;
        // max-width: 318px;
        .cds--tabs__nav-item,
        .cds--tabs__nav-link {
          font-size: 12px;
          font-weight: 500;
          // line-height: 19px;
          letter-spacing: 0;
          color: $color-grey-2 !important;
          flex: unset;
          .cds--tabs__nav-item-label-wrapper {
            align-items: center;
            justify-content: center;
          }
        }
        .cds--tabs__nav-item--selected {
          font-weight: 600;
          background: $color-grey-1;
          color: $color-white !important;
        }
        .cds--tabs__nav-link,
        .cds--tabs__nav-item--selected,
        .cds--tabs__nav-link:focus,
        .cds--tabs__nav-link:active,
        .cds--tabs__nav-item:not(.cds--tabs__nav-item--selected):not(
            .cds--tabs__nav-item--disabled
          ):hover {
          color: inherit;
          border-block-end: unset !important;
          outline: unset !important;
        }
        .cds--tabs__nav-item {
          position: relative;
        }
        .cds--tabs__nav-item:not(.cds--tabs__nav-item--selected)::after {
          content: "";
          position: absolute;
          border-left: 1px solid $color-grey-2;
          height: 50%;
          top: 6px;
          left: 0;
        }
        .cds--tabs__nav-item--selected + .cds--tabs__nav-item::after,
        .cds--tabs__nav-item:first-child::after {
          display: none;
        }
      }
    }
  }
}
.shareholding-chart-large {
  &__header {
    color: var(--color-grey-1);
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    line-height: 21.78px;
  }
}
.peer-comparison-chart-large__tab__scroll {
  max-width: 470px;
}
.peers-chart__tab__list {
  width: fit-content;
}
.share-chart-card__tab {
  position: relative;
  &__header_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 2%;
  }
  &__inline_row {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid var(--color-grey-5);
    padding: 0px 10px 0px 0px;
    p {
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      color: $color-grey-2;
    }
    &__value {
      text-align: right;
      font-size: 14px;
      color: $color-grey-2;
      font-weight: 600 !important;
    }
  }
  &__inline_row:last-child {
    border: none;
  }
  &__chart {
    .highcharts-tick {
      stroke: #bdbdbd;
    }
  }
  &__details__table {
    border-collapse: collapse;
    border-style: hidden;
    width: 100%;
    tr {
      td {
        padding: 4px 12px;
        font-size: 14px;
        font-weight: 400;
        color: $color-grey-2;
        width: 25%;
        &:nth-child(2) {
          border-right: 1px solid $color-grey-5;
        }
        &:nth-child(odd) {
          text-align: left;
        }
        &:nth-child(even) {
          text-align: right;
          font-weight: 500;
          color: $color-grey-1;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      &:nth-child(2) {
        border-top: 1px solid $color-grey-5;
      }
    }
  }
}

.share-chart-card-tab__header {
  &__haeder_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}

.share-chart-card-tab__header,
.share-performance-tab__header,
.peers-chart-tab__header {
  font-size: 19px;
  font-weight: 600;
  line-height: 29.05px;
  padding-bottom: 41px;
}
.share-chart-card__tab__market {
  display: flex;
  justify-content: space-between;
  &__data {
    color: $color-grey-1;
    &__company {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      padding-bottom: 5px;
    }
    &__value {
      display: flex;
      align-items: baseline;
      &__format {
        font-size: 22px;
        font-weight: 700;
        line-height: 24.2px;
      }
      &__currency {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        color: $color-grey-2;
      }
      &__percentage {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    }
  }
  &__icon {
    position: absolute;
    top: 2px;
    right: 0;
  }
  &__market__icon,
  &__icon {
    padding-top: 7px;
    &__open,
    &__close {
      display: flex;
      font-size: 10px;
      font-weight: 400;
      line-height: 13.31px;
      color: $color-grey-3;
      text-transform: uppercase;
    }
  }
  &__company {
    font-size: 14px;
    font-weight: 600;
    color: $color-grey-1 !important;
  }
  &__close {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    color: $color-grey-3;
    padding: 12px 0;
    &__reopen {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
      color: $color-grey-1;
    }
  }
}
.share-chart-card__tab__market {
  margin: 4px 0 0;
}
.share-chart-card-tab__header,
.share-performance-tab__header,
.peers-chart-tab__header {
  padding-bottom: 5px;
}

.peers-chart__tab__head {
  padding-top: 10px;
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    color: $color-grey-1;
    padding-bottom: 5px;
  }
  &__details {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    color: $color-grey-2;
    &__company {
      font-size: 14px;
      font-weight: 600;
      color: $color-grey-1 !important;
      padding-bottom: 5px;
    }
    &__high {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
      color: $color-grey-1;
      padding-right: 5px;
    }
    &__color {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      padding-left: 5px;
    }
  }
}
.shareholding-chart-large__tab__scroll__list {
  position: absolute;
  top: 13px;
  width: fit-content;
  right: 13px;
}
.shareholding-history-card__tab__dropdown,
.shareholding-history-large__tab__dropdown {
  &__ele {
    .cds--dropdown {
      width: 220px;
      height: 40px;
      border-bottom: 1px solid $color-grey-4;
      .cds--list-box__label {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: $color-grey-2;
      }
      .cds--list-box__field {
        padding-inline-start: 0.4rem;
        padding-inline-end: 1.4rem;
      }
      .cds--list-box__menu {
        height: 160px;
        &-icon {
          right: 6px;
          svg {
            fill: $color-grey-2;
          }
        }
      }

      .cds--list-box__menu-item,
    .cds--list-box__menu-item:active,
    // .cds--list-box__menu-item:focus,
    .cds--list-box__menu-item__option {
        border-block-start-style: unset;
        border-block-end-style: unset;
        border: unset;
        background: $color-white;
        padding-right: 0;
        outline: unset;
      }
      .cds--list-box__menu-item__option {
        font-size: 16px;
        letter-spacing: 0;
        color: $color-grey-2;
        margin-right: 0;
        margin-left: 8px;
      }
      .cds--list-box__menu-item__selected-icon {
        display: none;
      }
    }
  }
}
.shareholding-history-large__tab__dropdown {
  position: absolute;
  top: 13px;
  right: 13px;
}
.share-performance {
  &__selection {
    &__txt {
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
    }
  }
  &__market-close {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.36px;
    color: $color-grey-1;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 5px;
    &__area {
      display: flex;
      align-items: center;
      &__txt {
        font-size: 11px;
        font-weight: 400;
        line-height: 13.31px;
        color: $color-grey-3;
        margin-left: 5px;
      }
    }
  }
  &__value {
    font-size: 40px;
    padding: 5px 0;
    font-weight: 600;
    color: $color-grey-1;
    &__code {
      font-weight: 400;
    }
  }
  &__data__table {
    width: 100%;
    td {
      padding: 5px 0;
      &:first-child {
        color: $color-grey-2;
      }
      &:last-child {
        color: $color-grey-1;
      }
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    &__card {
      width: 72px;
      height: 65px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      &__duration {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
      }
      &__change-percentage {
        margin-top: auto;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.green {
  color: $color-green-2;
}
.red {
  color: $color-red;
}
.grey {
  color: $color-grey-3;
}
.earnings-revenue {
  &__title {
    font-size: 19px;
    font-weight: 600;
    line-height: 29.05px;
    color: $color-grey-1;
  }
}

.trend-chart,
.doubleBarXl-chart,
.doubleBar-line-chart,
.double-bar-chart,
.bar-line-chart,
.bar-chart,
.double-line-chart,
.speedometer-chart,
.debt-line-chart,
.scatter-plot-debt-chart,
.earnings-dot-chart,
.donut-revenue-chart,
.donut-country-chart,
.revenue-sankey-chart {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    color: $color-grey-1;
    font-size: 19px;
    font-weight: 600;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    line-height: 21.78px;
    padding-bottom: 12px;
    &__sub {
      font-weight: 400;
      background: $color-grey-2;
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 12px;
      line-height: 14.5px;
      color: $color-grey-6;
    }
  }
}

.revenue-sankey-chart {
  height: auto;
  pointer-events: none;
  &__legend_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 7px;
    div {
      display: flex;
      flex-direction: row;
      gap: 2px;
    }
    p {
      font-size: 11px;
      font-weight: 400;
      color: #333333;
    }
  }
}
// .speedometer-chart,
// .doubleBar-line-chart,
// .double-bar-chart,
// .bar-line-chart,
// .bar-chart,
// .double-line-chart,
// .debt-line-chart,
// .scatter-plot-debt-chart {
//   &__header {
//     padding-bottom: 6px;
//     &__sub {
//       padding: 2px 4px;
//     }
//   }
// }

.share-chart-card__selection,
.share-performance__selection,
.peers-chart-card__selection {
  &__dropdown .cds--dropdown {
    width: 200px;
    height: 27px;
    background: $color-grey-6;

    .cds--list-box__label {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0;
      color: $color-grey-2;
    }
    .cds--list-box__field {
      padding-left: 8px;
      padding-right: 20px;
    }
    .cds--list-box__menu-icon {
      right: 0;
      svg {
        fill: $color-grey-2;
      }
    }
    .cds--list-box__menu-item,
    .cds--list-box__menu-item:active,
    // .cds--list-box__menu-item:focus,
    .cds--list-box__menu-item__option {
      border-block-start-style: unset;
      border-block-end-style: unset;
      border: unset;
      background: $color-grey-6;
      padding-right: 0;
      outline: unset;
      height: 30px;
    }
    .cds--list-box__menu-item__option {
      font-size: 16px;
      padding: 8px 0;
      letter-spacing: 0;
      color: $color-grey-2;
      margin-right: 0;
      margin-left: 8px;
    }
    .cds--list-box__menu-item__selected-icon {
      display: none;
    }
    .cds--list-box__menu-item:active .cds--list-box__menu-item__option {
      color: $color-blue;
    }
  }
}
.peers-chart_icon_row {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 6%;
  right: 2%;
}

.splide__arrows {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  top: -10%;
}

.splide__arrow {
  background: transparent !important;
}

.custom-prev,
.custom-next {
  background-color: #4a91e200;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 30px;
}
.splide__arrow--prev {
  left: -80px !important;
}

.splide__arrow svg {
  display: none; /* Hide the original SVG */
}

.splide__arrow--next::after {
  content: "->";
  display: block;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splide__arrow--prev::after {
  content: "<-";
  display: block;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splide__arrow:disabled {
  opacity: 1;
}

.empty-slide {
  width: 342px;
  height: 342px;
  padding: 13px;
}
