@font-face {
  font-family: $fontInter;
  font-weight: 300;
  font-display: swap;
  src: url("./Inter/Inter-Light.ttf");
}

@font-face {
  font-family: $fontInter;
  font-weight: 400;
  font-display: swap;
  src: url("./Inter/Inter-Regular.ttf");
}

@font-face {
  font-family: $fontInter;
  font-weight: 500;
  font-display: swap;
  src: url("./Inter/Inter-Medium.ttf");
}

@font-face {
  font-family: $fontInter;
  font-weight: 600;
  font-display: swap;
  src: url("./Inter/Inter-SemiBold.ttf");
}

@font-face {
  font-family: $fontInter;
  font-weight: 200;
  font-display: swap;
  src: url("./Inter/Inter-ExtraLight.ttf");
}