.announcement-card {
  height: 136px;
  &__header {
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
  &__body {
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    color: $color-grey-2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-align: justify;
    letter-spacing: 0;
    margin-top: 10px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    &__date {
      font-size: 11px;
      font-weight: 400;
      line-height: 18px;
      color: $color-grey-1;
    }
    &__percentage {
      padding: 2px 8px 2px 8px;
      border-radius: 4px;
      font-size: 11px;
      font-weight: 500;
      line-height: 18px;
      background: #ffd6d6;
      color: $color-red;
      &.active {
        background: #d6ffe7;
        color: $color-green-2;
      }
    }
  }
}
