.peers-profit-loss-card {
  &__header {
    color: $color-grey-1;
    font-size: 19px;
    font-weight: 600;
    line-height: 21.78px;
    padding-bottom: 10px;
  }
  &__body {
    &__list {
      border-bottom: 1px solid #e3e3e3;
      padding: 10px 0;
      &__col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__shortName {
          font-size: 12px;
          font-weight: 500;
          line-height: 14.52px;
          color: $color-black;
          display: flex;
          align-items: center;
          &__symbol {
            color: $color-grey-1;
            background: $color-grey-5;
            font-size: 6.63px;
            font-weight: 500;
            line-height: 8.62px;
            letter-spacing: 0.03em;
            padding: 2.47px 2.95px 1.47px 1.95px;
            border-radius: 1.47px;
          }
        }
        &__price {
          font-size: 15px;
          font-weight: 700;
          line-height: 18.15px;
          color: $color-grey-1;
          &__currency {
            font-size: 10px;
            font-weight: 400;
            line-height: 15.48px;
            color: $color-grey-2;
          }
        }
        &__name {
          font-size: 10px;
          font-weight: 400;
          line-height: 13.27px;
          color: $color-grey-2;
        }
        &__percentage {
          font-size: 12px;
          font-weight: 500;
          line-height: 17.96px;
        }
      }
    }
    &__list:last-child {
      border: unset;
    }
  }
}
