.poweredBy {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  p {
    color: $color-grey-3;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 500;
    span {
      color: $color-grey-2;
    }
  }
  a {
    margin: 0.5rem;
    color: #2f80ed;
  }
}
