.doubleBarXl-chart,
.revenue-wave-chart {
  position: relative;
  &__forecast {
    &__bg {
      position: absolute;
      margin-top: 2px;
      // opacity: .5;
      position: absolute;
      right: 46px;
      top: 42px;
      z-index: 1;
      height: 74%;
      background: url("../../../assets/images/overlay-lines.svg");
    }
    &__txt_container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__txt {
      position: absolute;
      font-size: 11.79px;
      font-weight: 500;
      line-height: 14.27px;
      padding: 3px 5px;
      border-radius: 4px;
      color: var(--color-white);
      background: var(--color-grey-2);
      border: 1px solid var(--color-grey-3);
      text-transform: uppercase;
      z-index: 2;
    }
  }
}
.revenue-wave-chart {
  &__forecast {
    &__bg {
      right: 47px !important;
      height: 74% !important;
      margin-top: 0 !important;
    }
    &__txt {
      top: 180px;
      right: 162px;
    }
  }
}

// hide functionality of labels
.highcharts-legend-item {
  pointer-events: none !important; /* Disable pointer events on legend items */
}
// hide bar's highlighter
.highcharts-crosshair.highcharts-crosshair-category {
  visibility: hidden !important;
}
.spline-chart {
  padding-top: 5px;
  &__legend {
    display: flex;
    justify-content: center;
    gap: 20px;
    overflow-x: auto;
    width: 100%;

    &__item {
      display: flex;
      line-height: 15px;
      font-size: 12px;
      color: $color-grey-3;
      cursor: pointer;
      &__box {
        font-size: 16px;
        margin-right: 2px;
      }
    }
    &__checkbox {
      margin-top: -2px;
    }
    input[type="checkbox"] {
      width: 14px !important;
      height: 14px !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      box-shadow: none;
      text-align: center;
      line-height: 1.1em;
    }
    input[type="checkbox"]:checked:after {
      content: "✔";
      color: white;
    }
  }

  &__legend::-webkit-scrollbar {
    width: 5px !important;
    height: 3px;
  }
  &__legend::-webkit-scrollbar-track {
    background: #fff !important;
  }

  &__legend::-webkit-scrollbar-thumb {
    background-color: #d8d8d8 !important;
    border-radius: 4px !important;
    height: 2px;
  }

  &__legend::-webkit-scrollbar-thumb:hover {
    background-color: #d3d2d2 !important;
    cursor: grab;
  }
}
.intraday-chart {
  position: relative;
  padding-bottom: 5px;
  &__label {
    position: absolute;
    bottom: 0;
    &__txt {
      color: $color-grey-2;
      font-size: 13px;
      padding-left: 32px;
    }
  }
}

//Document (Investor Kit)
.investor-kit {
  &__investor_data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 4%;
  }
  &__title {
    font-weight: 600;
    font-size: 15px;
  }
  &__header {
    font-weight: 600;
    font-size: 18px;
  }
  &__investorIcon {
    width: 10%;
  }
  &__investorIcon img {
    height: 24px;
    width: 24px;
  }
  &__hr_custom {
    width: 100%;
    padding: 0;
    margin-top: 12px;
    background-color: $color-grey-6;
    border: none;
    height: 0.1px;
  }
  &__title {
    color: $color-grey-1;
    font-weight: 400;
    font-size: 14px;
  }
}

.sales-growth {
  &__sale_box {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    // margin-top: 10px;
  }
  &__sales_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px 10px 10px 10px;
  }
  &__sales_row p {
    font-size: 13px;
    font-weight: 400;
    color: $color-grey-1;
  }
  &__value {
    font-weight: 600 !important;
  }
  &__hr_custom {
    width: 100%;
    padding: 0;
    margin: 5px;
    background-color: #d7d7d7;
    border: none;
    height: 0.1px;
    margin: 0;
  }
}

//greetings card

.greetings-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 342px;

  &__content_title {
    font-weight: 600;
    margin-top: 5%;
    color: $color-grey-1;
  }
  &__content_msg {
    font-weight: 400;
    margin-top: 4%;
    text-align: center;
    width: 90%;
    color: $color-grey-2;
  }
}

.indices-data-card {
  &__header_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__header_text {
    font-size: 20px;
    font-weight: 600;
    color: $color-grey-1;
  }
  &__market_closed {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__market_text {
    font-size: 10px;
    font-weight: 400;
    color: $color-grey-3;
    text-transform: uppercase;
    margin-left: 3px;
  }
  h6 {
    font-size: 14px;
  }
  &__text_muted {
    font-size: 11px;
    font-weight: 400;
    color: $color-grey-2;
    margin-top: 6%;
  }
  &__value_text {
    font-size: 12px;
    font-weight: 500;
    margin-top: 6%;
  }
  &__hr_custom {
    width: 100%;
    padding: 0;
    margin-top: 12px;
    background-color: $color-grey-6;
    border: none;
    height: 0.1px;
  }
  &__indice_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3%;
  }
  &__indice_value_column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.price-forecast {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &__header {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    line-height: 29.05px;
  }
  &__spline_chart {
    width: 70%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__card {
    width: 30%;
    padding: 1%;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
  }
}

.barchart-rangeslider {
  .highcharts-point {
    pointer-events: none; /* Disable hover effects */
  }
  .cds--slider-container {
    width: 100%;
    margin-top: -22px;
  }
  .cds--slider__filled-track {
    height: 8px;
  }
  .cds--slider__track {
    height: 10px;
    background-color: #ccc;
  }
  .cds--slider__thumb {
    width: 30px; /* Set width of the thumb */
    background: url("../../../assets/images/chart-slider.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
  }

  &__slider_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 100%;
  }

  &__range_box {
    background-color: #f9f9f9;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-left: 3%;
  }
  &__flex_row_progress_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__column_row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
  }
  &__submit-btn {
    color: #fff;
    background-color: #4040ab;
    border-radius: 5px;
    padding: 10px;
    border: none;
  }
  &__column {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cds--progress-bar__bar {
    background-color: #f0f0f0; /* Background color of the progress bar track */
  }
}

//text Response Widget

.text-response {
  height: 100%;
  &__row-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 5px;
  }
  &__pointer {
    font-size: 15px;
    list-style: auto;
    margin-left: 21px;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: baseline;
    justify-content: space-between;
  }
  &__title_text {
    font-size: 16px;
    font-weight: 600;
    // margin-left: 5px;
  }
  &__content_text {
    font-size: 15px;
    font-weight: 400;
  }
}

.profit-table {
  max-height: 282px; /* Set the desired table height */
  overflow-y: auto;

  &__container {
    max-height: inherit;
    overflow-y: auto; /* Set the overflow here as well */
  }

  &__header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  .cds--data-table tr td {
    font-size: 13px;
    font-weight: 500;
    text-align: end;
  }

  .cds--data-table tr {
    border: none;
    block-size: 33.5px;
    inline-size: 100%;
    background-color: #ffff;
  }

  .cds--table-header-label {
    text-align: end;
    font-weight: 500;
    min-height: 27px;
  }

  .cds--data-table tbody tr:not([data-child-row]):hover,
  .cds--data-table tbody tr[data-child-row]:hover > td {
    background-color: #f9f9f9 !important;
  }
  .cds--data-table tbody tr:not([data-child-row]),
  .cds--data-table tbody tr[data-child-row] > td {
    border-bottom: 1px solid #f9f9f9 !important;
  }

  /* Custom scrollbar styling with !important */
  ::-webkit-scrollbar {
    width: 5px !important;
  }

  ::-webkit-scrollbar-track {
    background: #fff !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d8d8d8 !important;
    border-radius: 4px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important;
  }
}

.forecast-spline-chart:hover {
  pointer-events: none;
}
