.title-bar-container {
  padding: 12px 0px;
  border-radius: 12px;
  // background: $defaultBackground;
  color: $color-grey-1;
  font-size: 20px;
  font-weight: 500;
  width: fit-content;
  line-height: 36px;
}
