.related-search-container {
  padding-top: 2rem;
  border-top: 1px solid $color-grey-5;
  &__header {
    color: $color-grey-1;
    font-size: 22px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0;
    margin-bottom: 1rem;
  }
  &__body {
    // display: inline-flex;
    // flex-wrap: wrap;
    // gap: 18px;
    border-top: 1px solid $color-grey-5;
  }
  &__box {
    // border-radius: 4px;
    background-color: $color-white;
    padding: 12px 0px;
    // display: flex;
    cursor: pointer;
    // border: 1px solid $color-grey-4;
    // flex-wrap: wrap;
    border-bottom: 1px solid $color-grey-5;
    @media screen and (max-width: 768px) {
      width: 100%; // Single column for smaller screens
    }
  }
  &__content {
    color: $color-grey-2;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    margin: 0;
  }
}
