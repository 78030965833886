.common-card-large {
  // width: 952px;
  width: 707px;
  height: 342px;
  padding: 13px;
  box-shadow: 0px 0px 10px 0px #8b8b8b1f;
  background: $color-white;
  position: relative;
  &.xl {
    width: 1070px;
  }
  &__head {
    font-size: 19px;
    font-weight: 600;
    line-height: 20.57px;
    color: $color-grey-1;
    width: fit-content;
    // border-bottom: 1px solid $color-grey-5;
    padding-bottom: 14px;
    margin: 0;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    &__company {
      font-weight: 600;
    }
  }
  &__list {
    height: 295px;
  }
  @media screen and (max-width: 900px) {
    &__head {
      font-size: 15px;
    }
  }
}
