.rating-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 320px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 3px; /* Reduce scrollbar width */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* For Firefox */
  scrollbar-width: thin; /* Set scrollbar width to thin */
  scrollbar-color: #888 #f1f1f1; /* Set thumb and track colors */
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 291px;
  }
  &__title {
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: var(--color-grey-1);
  }
  &__text-muted {
    font-size: 12px;
    font-weight: 400;
    color: $color-grey-3;
  }
  &__rating-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__question_name {
    font-size: 13px;
    font-weight: 500;
    color: $color-grey-1;
    padding-bottom: 2%;
    margin-top: 2%;
  }
  &__question_name_two {
    font-size: 13px;
    font-weight: 500;
    color: var(--color-grey-1);
    margin-top: 4%;
  }
  &__label_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__maped_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5%;
    margin-top: 2%;
  }
  &__box {
    border: 1px solid #bdbdbd;
    padding: 2px 7px 2px 7px;
    cursor: pointer;
  }
  &__box p {
    font-size: 11px;
  }
  &__submit_btn_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  &__submit-btn {
    background-color: #4040ab;
    color: #fff;
    width: 28%;
    padding: 8px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    margin-top: 4%;
    border: none;
  }
  &__maped_row_two {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2%;
    margin-top: 2%;
  }
  &__box_two {
    border: 1px solid #bdbdbd;
    padding: 5px 7px 5px 7px;
    cursor: pointer;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
  }
  &__box_two p {
    font-size: 11px;
    font-weight: 400;
  }
}
