.earning {
  &-call-subscribe {
    text-align: center;
    &__duration {
      background-image: url("../../../assets/images/earning_bg.svg");
      background-size: cover;
      background-position: center;
      width: 200px;
      height: 190px;
      margin: 0px auto 15px;
      display: flex;
      align-items: flex-end;
      color: $color-white;
      font-size: 16px;
      font-weight: 600;
      padding: 14px;
      text-align: left;
    }

    &__upcoming {
      font-size: 13px;
      color: $color-black; // Grey color for the subheading
      margin-bottom: 10px;
    }

    &__date {
      font-size: 18px;
      font-weight: 600;
      color: $color-black; // Black color for the date
      margin-bottom: 10px;
    }

    &__sub {
      display: inline-block;
      padding: 10px 35px;
      font-weight: 500;
      font-size: 14px;
      color: $color-grey-1;
      cursor: pointer;
      margin-bottom: 10px;
      border: 1px solid $color-grey-1;
    }

    &__footer {
      font-weight: 500;
      font-size: 9px;
      color: $color-grey-3;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      &__powered {
        font-weight: 300;
      }
      img {
        width: 12px; // Smaller icon size for the app store icons
        height: 12px;
      }
    }
  }
  &-audio-player {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    &__custom-audio-player {
      width: 58%;
    }
    &__audio-list {
      width: 38%;
      display: flex;
      flex-direction: column;
      margin-top: -1%;
    }
    &__related_earings {
      color: #333333;
      font-weight: 600;
      font-size: 12px;
    }
    &__img_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    &__event_name_card {
      position: absolute;
      color: #fff;
      bottom: 14%;
      font-weight: 500;
      left: 6%;
    }
    &__play_list_event_name_card {
      position: absolute;
      color: #fff;
      bottom: 10%;
      font-weight: 400;
      font-size: 10px;
      left: 5%;
    }
    &__playing_img {
      width: 95%;
      height: 210px;
    }
    &__play_card_name {
      margin-top: 4%;
      margin-bottom: -1%;
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 400;
    }
    &__audio_list_thumbnail {
      width: 100%;
      height: 77px;
      margin-bottom: 5%;
      margin-top: 5%;
    }
    &__custom-controls {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    .rhap_main-controls {
      margin-top: 5px;
    }
    .rhap_button-clear {
      background-color: transparent;
      border: none;
      padding: 0;
      overflow: hidden;
      cursor: pointer;
      align-items: center;
      display: grid;
      justify-content: center;
    }

    .rhap_container {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      line-height: 1;
      font-family: inherit;
      width: 100%;
      padding: 15px 8px;
      background-color: #fff;
      box-shadow: none;
    }

    .rhap_progress-section {
      display: flex;
      align-items: center;
      position: relative;
    }
    .rhap_current-time {
      position: absolute;
      padding-top: 20%;
    }
    .rhap_total-time {
      position: absolute;
      right: 0%;
      margin-top: 20%;
    }

    .rhap_progress-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: absolute;
    }

    .rhap_progress-bar {
      margin: 0 -10px;
      height: 3px;
      background-color: #e0e0e0;
    }
    .rhap_progress-filled {
      height: 100%;
      position: absolute;
      z-index: 2;
      background-color: #4f4f4f;
      border-radius: 2px;
    }
    .rhap_download-progress {
      height: 100%;
      position: absolute;
      z-index: 1;
      background-color: #E0E0E0;
      border-radius: 2px;
  }

    .rhap_progress-indicator {
      background-color: #000;
      width: 7px;
      height: 7px;
      margin-top: 6px;
      margin-left: 0px;
    }

    .rhap_time {
      font-size: 12px;
      color: #333;
      font-weight: 400;
    }
    &__event_pwrd_by_text {
      font-size: 9px;
      color: #828282;
      font-weight: 400;
    }
    &__event_pwrd_by_text span {
      font-size: 10px;
      font-weight: 500;
    }
    &__download_app {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__download_app_text {
      font-size: 10px;
      margin-right: 2%;
      color: #828282;
      font-weight: 500;
    }
    &__single_card_pwrd_by {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      p {
        padding-right: 6%;
      }
      img {
        padding-right: 3%;
      }
    }
    &__audio_list_item {
      cursor: pointer;
    }
    .rhap_controls-section {
      margin-top: 18px !important;
    }
  }
}
