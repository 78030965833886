.common-card__list {
  .last-dividend-paid-card {
    display: flex;
    flex-direction: column;
    gap: 2px;
    &__value {
      padding: 10px 10px 4px;
      background: #e8f2ff;
      border-left: 4px solid $color-blue;
      &__head {
        font-size: 18px;
        font-weight: 400;
        color: $color-grey-3;
      }
      &__body {
        font-size: 40px;
        color: $color-grey-1;
        font-weight: 500;
        span {
          font-weight: 400;
          color: $color-grey-2;
        }
      }
    }
    &__list {
      display: flex;
      justify-content: space-between;
      padding: 11px 0;
      &__content {
        font-size: 15px;
        font-weight: 500;
        color: $color-grey-2;
      }
      &:not(:last-child) {
        border-bottom: 0.5px solid $color-grey-6;
      }
    }
    :last-child {
      color: $color-grey-1;
    }
  }
}
