.peer-comparison-chart-large {
  &__tab {
    position: relative;
    &__header {
      font-size: 19px;
      font-weight: 600;
      line-height: 29.05px;
    }
    &__scroll {
      position: absolute;
      top: 3px;
      left: 200px;
    }
  }
}