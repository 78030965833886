.answer-bar-container {
  // padding: 24px 0;
  border-radius: 12px;
  margin: 0 auto;
  margin-bottom: 2rem;
  &__header{
    font-size: 20px;
    color: $color-grey-1;
    margin-bottom: 12px;
    font-weight: 600;
    display: flex;
    gap: 12px;
    span{
      padding: 0 2px;
      background-color: $color-blue;
    }
  }
  &__content {
    color: $color-grey-1;
    font-size: 22px;
    line-height: 24px;
    margin: 0;
    // text-align: justify;
    white-space: pre-wrap;
    p{
      font-size: 22px;
      line-height: 24px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 1em;
    }
    
    th, td {
      border: 1px solid #ddd;
      padding: 8px;
    }
    
    th {
      background-color: #f6f8fa;
      text-align: left;
    }
    
    /* Code blocks styling */
    pre {
      padding: 16px;
      background-color: #f6f8fa;
      border-radius: 6px;
      overflow: auto;
    }
    
    /* Inline code styling */
    p code {
      background-color: rgba(27,31,35,.05);
      padding: 0.2em 0.4em;
      margin: 0;
      border-radius: 3px;
    }
    
    /* Heading styling */
    h1, h2, h3, h4, h5, h6 {
      border-bottom: 1px solid #eaecef;
      padding-bottom: 0.3em;
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
    
    /* Other markdown elements */
    blockquote {
      padding: 0 1em;
      color: #6a737d;
      border-left: 0.25em solid #dfe2e5;
      margin-left: 0;
      margin-right: 0;
    }

  }
  &__icon-area {
    &-source{
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: $color-grey-3;
      margin-top: 32px;
    }
    &-actions{
      display: flex;
      align-items: center;
      gap: 18px;
      margin-top: 32px;
      &-btn{
        display: flex;
        padding: 12px;
        border: 1px solid $color-grey-4;
        border-radius: 4px;
        cursor: pointer;
        img{
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
