.donut-chart,
.donut-revenue-chart,
.donut-country-chart {
  .highcharts-subtitle__value {
    font-size: 28px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0;
    color: $color-green-2;
  }
  .highcharts-legend-item {
    text {
      font-size: 12px;
      color: $color-grey-2 !important;
      fill: $color-grey-2 !important;
    }
    .highcharts-point {
      rx: 0;
    }
  }
}
.donut-revenue-chart,
.donut-country-chart {
  .highcharts-subtitle__revenue-value {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: $color-grey-1;
  }
  .highcharts-subtitle__revenue-detail {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $color-grey-1;
  }
  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px 7px;
    height: 42px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px !important;
      // height: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #fff !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d8d8d8 !important;
      border-radius: 4px !important;
      height: 2px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #d3d2d2 !important;
      cursor: grab;
    }
    div {
      display: flex;
      flex-direction: row;
      gap: 2px;
    }
    p {
      font-size: 11px;
      font-weight: 400;
      color: #4f4f4f;
    }
  }
}
